.container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  grid-column-start: span 2;
}

.checkbox {
  align-items: center;
  column-gap: 0.5rem;
  cursor: pointer;
  color: var(--primary-2);
  font-family: var(--font-regular);
  font-size: calc(var(--fmob-size-12px) + 2px);
  font-weight: var(--regular-w);
}
.checkbox.noTextClick {
  cursor: default;
}

.desktop.checkbox {
}

.checkbox b {
  color: var(--primary-2);
  font-style: normal;
  font-family: var(--font-bold);
  font-weight: var(--bold-w);
  font-size: calc(var(--fmob-size-12px) + 2px);
}

.checkbox input[type="checkbox"] {
  flex-shrink: 0;
  justify-content: center;
  appearance: none;
  -webkit-appearance: none;
  height: 1.25rem;
  width: 1.25rem;
  cursor: pointer;
  background-color: var(--ui-1);
  border-radius: var(--checkbox-corner);
  border: 1px solid var(--ui-4);
  outline: none;
}

.checkbox input[type="checkbox"]:checked {
  background-color: var(--primary-1);
  border-color: var(--primary-1);
}

.desktop.checkbox input[type="checkbox"] {
  background-color: var(--primary-1);
  border-color: var(--ui-1);
}
.desktop.checkbox input[type="checkbox"]:checked {
  background-color: var(--ui-1);
}

.checkbox .checked {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.desktop.checkbox .checked svg {
  position: absolute;
  margin-bottom: 2px;
}

.checkbox .checked svg {
  position: absolute;
  margin-bottom: 2px;
  scale: 1.25;
}
