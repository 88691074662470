form.desktop.multi {
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
}

form.desktop.multi.results {
  margin: 0.5rem 0 0.75rem;
  row-gap: 0.5rem;
}

form.desktop.multi.results div:global(.grid-row-multi) {
  height: 3rem;
}

form.desktop.multi div:global(.grid-row-multi),
form.desktop.multi.results > div:last-of-type {
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 33.33%);
  align-items: center;
  margin: 0;
}

form.desktop.multi.results > div:last-of-type button {
  width: 100%;
}

form.desktop.multi.results > div:last-of-type div {
  width: 96%;
  justify-self: center;
}

@media only screen and (max-width: 1439px) and (min-width: 991px) {
  form.desktop.multi div:global(.grid-row-multi),
  form.desktop.multi.results > div:last-of-type {
    grid-template-columns: repeat(3, 33.33%);
  }
}

.remove {
  position: absolute;
  right: -2.25rem;
  border: none;
  background: none;
  justify-self: end;
  padding: 0;
}

.remove,
.addButton {
  cursor: pointer;
}

form.desktop.multi.results .remove svg path {
  fill: var(--primary-2);
}

.addButton,
.passengers {
  border: none;
  border-radius: var(--corner-md);
  background-color: var(--ui-1);
  height: 100%;
}

.addButton {
  display: flex;
  color: var(--primary-1);
  font-family: var(--font-semibold);
  font-size: var(--f-size-16px);
  font-weight: var(--semibold-w);
  align-items: center;
  padding: 0;
  column-gap: 0.75rem;
  padding-left: 1.25rem;
}

.addButton.disabled {
  cursor: default;
  color: #bbbbbb;
}

form.desktop.multi.results .addButton {
  border: 1px solid var(--ui-4);
  border-radius: var(--corner-sm);
  height: 3rem;
  font-size: var(--f-size-16px);
}

form.desktop.multi.results .addButton svg {
  transform: scale(0.84);
}

form.desktop.multi .passengers + button:last-of-type {
  height: 5rem;
  background-color: var(--search-button-color);
  border-right-color: transparent;
  text-align: center;
  margin: 0;
  grid-column: 3 / 4;
  font-size: var(--f-size-18px);
  font-family: var(--font-bold);
  font-weight: var(--bold-w);
}

form.desktop.multi .passengers + button:last-of-type:hover {
  background-color: var(--search-button-hover-color);
}

form.desktop.multi.results .passengers {
  border-radius: var(--corner-sm);
}

form.desktop.multi.results .passengers + button:last-of-type {
  height: 3rem;
  border-radius: var(--corner-sm);
  font-size: 1rem;
  background-color: var(--primary-1);
}

form.desktop.multi.results .passengers + button:last-of-type:hover {
  background-color: var(--primary-btn-hover-color);
}

form.desktop.multi > div:last-of-type {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  column-gap: 0;
  cursor: default;
  pointer-events: none;
}

form.desktop.multi > div:last-of-type span {
  position: relative;
  grid-row-start: 1;
  justify-self: end;
  pointer-events: all;
}

form.desktop.multi.results > div:last-of-type {
  height: 3rem;
}

.checkbox2 {
  margin-left: 0.5rem;
}

.checkbox2,
.checkbox2 b {
  color: var(--ui-1);
  cursor: pointer;
  font-size: var(--f-size-16px);
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
}
