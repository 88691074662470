main#business-booking-page,
main#group-booking-page {
  margin-bottom: 3rem;
}
main#business-booking-page .padded-view,
main#group-booking-page .padded-view {
  padding: 0 var(--main-page-side-pad);
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  height: fit-content;
  position: relative;
  margin: 0 auto;
}
.header-container {
  position: relative;
  margin: 0 calc(-1 * var(--main-page-side-pad));
  height: 23.75rem;
  text-align: center;
  position: relative;
  overflow: hidden;
}
main#business-booking-page .header-graphic,
main#group-booking-page .header-graphic {
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  height: 100%;
  width: 100%;
  background-size: cover;
  border-bottom: 1px var(--ui-4) solid;
}

main#group-booking-page .header-graphic {
  background-image: url("src/assets/header_img_group.png");
  filter: brightness(0.9) opacity(0.9) saturate(1.5);
}

main#business-booking-page .header-graphic {
  filter: brightness(0.6) saturate(1.75);
  background-image: url("src/assets/header_img_group_biz.jpg");
}

main#business-booking-page .header-content {
  color: white;
}

.header-container .header-content {
  padding: 0 15rem;
  margin: 5.43rem 0;
}
.header-container .header-content h1 {
  font-family: var(--font-bold);
  font-weight: var(--bold-w);
  font-size: var(--f-size-40px);
}
.header-container .header-content p:first-of-type {
  font-size: var(--f-size-20px);
  margin-top: 1.5rem;
}
.header-container .header-content p:last-of-type {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--f-size-20px);
  margin-top: 0.75rem;
}
.group-booking__header h2 {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--f-size-32px);
  margin-top: 2.5rem;
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
}
.group-booking__header .group-info-tag {
  background-color: var(--ui-3);
  color: var(--ui-5);
  margin-left: 0.75rem;
}

form#group-booking-form {
  display: contents;
}
.form-container {
  width: 100%;
  padding: 1.875rem;
}
.divider {
  border-bottom: 1px solid var(--ui-2);
  margin: 0 -1.875rem;
}
.input-group {
  position: relative;
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: 0.9375rem;
}

.group-booking__travel,
.group-booking__info {
  margin-top: 1rem;
}
.post-group-booking {
  margin-top: 2rem;
  text-align: center;
}
.post-group-booking__card {
  display: flex;
  width: 70%;
  flex-direction: column;
  align-items: center;
  padding: 5rem 1rem 3.75rem;
  margin: 0 auto;
}
.post-group-booking__card h3 + svg {
  margin: 2rem 0;
  width: 13rem;
  height: auto;
}
.post-group-booking__card h3,
.group-booking__travel h3,
.group-booking__info h3 {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--f-size-24px);
  margin-bottom: 1.875rem;
}
.post-group-booking__back {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;
}
.group-info,
.travel-info {
  margin-top: 0.75rem;
}
.group-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-content: space-between;
  column-gap: 7%;
  row-gap: 1.25rem;
}
main#business-booking-page .group-info {
  grid-auto-flow: column;
  grid-template-rows: repeat(4, 1fr);
}

.travel-info .input-group .input-container,
.group-info .input-group .input-container {
  margin-top: 0.3125rem;
}
.group-booking__travel h4,
.group-booking__info h4 {
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  font-size: var(--f-size-20px);
  margin-top: 1.5rem;
}

.group-type {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;
}
.group-type .group-type-item button {
  display: flex;
  background: transparent;
  border: 1px solid var(--ui-4);
  border-radius: var(--corner-md);
  color: var(--primary-2);
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 0.75rem;
  align-items: center;
  column-gap: 0.5rem;
}
.group-type .group-type-item button:hover {
  background-color: var(--ui-2);
}
.group-type .group-type-item button.active {
  background-color: var(--primary-tint-dark);
  border-color: var(--primary-1-50);
}
.group-type .group-type-item button,
.group-type .group-type-item button.active,
.group-type .group-type-item button:hover {
  transition: all 0.2s;
}
.group-type .group-type-item .group-graphic {
  flex-shrink: 0;
  flex-basis: 25%;
  margin: auto 0;
}
.group-type .group-type-item .group-item-info {
  text-align: left;
}
.group-item-info .group-item-name {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--f-size-20px);
  margin-bottom: 0.5rem;
}

.travel-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 7%;
}
.travel-info .input-group {
  margin-top: 1.25rem;
}
.travel-passengers,
.travel-direction,
.group-info .group-contact-phone,
.travel-info .travel-dates {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}
.travel-info .additional-services {
  margin-top: 1rem;
}
.travel-info .additional-services li > div {
  margin-bottom: 0.5rem;
  padding: 0.75rem 1rem;
  border-radius: var(--corner-md);
  font-size: var(--f-size-16px);
  min-height: 3rem;
}
.travel-info .additional-services li.active > div {
  background-color: var(--primary-tint-dark);
}
.travel-info .additional-services li > div:hover {
  background-color: var(--ui-2);
}
.travel-info .additional-services li > div,
.travel-info .additional-services li.active > div,
.travel-info .additional-services li > div:hover {
  transition: all 0.2s;
}
.travel-info .divider {
  margin-top: 1.5rem;
}
.travel-info .bottom {
  margin-top: 0.25rem;
}
.travel-info .bottom .counter {
  color: var(--ui-4);
  font-size: var(--f-size-14px);
  font-family: var(--font-regular);
}
.travel-info .bottom textarea {
  height: 8rem;
  resize: none;
}
.travel-info .bottom button {
  margin-top: 1rem;
  height: 3.75rem;
  font-size: var(--f-size-18px);
}

.travel-info .travel-passengers .input-group,
.travel-info .travel-dates .input-group,
.group-info .group-contact-phone .input-group {
  flex-grow: 1;
}

/* MOBILE CSS OVERRIDES*/
main#business-booking-page.mobile,
main#group-booking-page.mobile {
  padding: 0 1rem;
  background-color: var(--UI-background);
}
.mobile .header-container {
  height: fit-content;
  width: calc(100% + 2rem);
  margin: 0 -1rem;
  padding: 1.5rem 0 2.5rem;
}
.mobile .header-container .header-content {
  margin: 0;
  position: relative;
  padding: 0 1rem;
}
.mobile .header-container .header-content h1 {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--f-size-20px);
}
.mobile .header-container .header-content p:first-of-type {
  font-size: var(--f-size-14px);
  margin-top: 1rem;
}
.mobile .header-container .header-content p:last-of-type {
  font-size: var(--f-size-14px);
}
.mobile .post-group-booking {
  margin-top: 2rem;
}
.mobile .post-group-booking__card {
  padding: 2rem 1rem;
  width: 100%;
}
.mobile .post-group-booking__card h3 + svg {
  width: 9rem;
}
.mobile .group-booking__header h2 {
  font-size: var(--f-size-20px);
  margin-top: 2rem;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0.75rem;
}
.mobile .group-booking__header .group-info-tag {
  margin-left: 0.5rem;
}
.mobile .form-container {
  padding: 1rem;
}
.mobile .divider {
  border-bottom: 1px solid var(--ui-2);
  margin: 0 -1rem;
}
.mobile .input-group {
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  font-size: 0.9375rem;
}
.mobile .post-group-booking h3,
.mobile .group-booking__travel h3,
.mobile .group-booking__info h3 {
  font-size: var(--f-size-18px);
  margin-bottom: 0.75rem;
}
.mobile .group-info {
  margin-top: 0.75rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}
.mobile .group-booking__travel h4,
.mobile .group-booking__info h4 {
  font-size: var(--f-size-16px);
  margin-top: 1.25rem;
}
.mobile .group-type {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.75rem;
}
.mobile .group-item-info .group-item-name {
  font-size: var(--f-size-16px);
  margin-bottom: 0.3rem;
}
.group-contact-phone > div.input-group:first-of-type {
  flex-grow: 0;
  flex-basis: 35%;
}
.mobile .travel-info {
  display: flex;
  flex-direction: column;
}
.mobile .travel-info .additional-services {
  margin-top: 0.5rem;
}
.mobile .bottom textarea#message {
  padding: 0.5rem 1rem;
}

.submision-error-modal,
.submision-error-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 1000;
}
.submision-error-modal-background {
  background-color: rgba(0, 0, 0, 0.5);
}
.submision-error-modal {
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
}
.submision-error-modal,
.submision-error-modal > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.submision-error-modal > div {
  width: 20rem;
  padding: 3rem;
  row-gap: 2rem;
  background-color: var(--UI-background);
  border-radius: var(--corner-md);
}
.submision-error-modal .submission-error {
  text-align: center;
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  font-size: var(--f-size-18px);
}
