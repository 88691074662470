button.increment {
  background: none;
  border: none;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  padding: 0;
  margin: 0;
  line-height: 0;
}

button.increment svg path {
  stroke: var(--primary-1);
  fill: var(--primary-1);
  transform-origin: center;
  transition: all 150ms linear;
}

button.increment svg > path + path {
  stroke: white;
}

button.increment:disabled svg path {
  stroke: rgb(211, 212, 215);
  fill: white;
}

button.increment.anim svg path {
  transform: scale(1.15);
  transition: all 150ms linear;
}
