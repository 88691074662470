.indicatorGroup {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
}

.indicator {
  border-radius: 50%;
  border-style: solid;
  border-color: var(--ui-4);
  border-width: 0.0625rem;
  flex-shrink: 0;
  width: 0.5rem;
  height: 0.5rem;
}

.indicator.active {
  background: var(--primary-2);
  border-color: var(--primary-2);
  border-radius: 50%;
  flex-shrink: 0;
}
