.container {
  display: flex;
  flex-direction: row;
}

li.container {
  justify-content: space-between;
  align-items: center;
}

.passenger {
  display: flex;
  flex-direction: column;
  row-gap: 0.12rem;
  justify-content: center;
  line-height: normal;
}

.passenger > span {
  color: var(--primary-2);
  font-family: var(--font-medium);
  font-size: calc(var(--fmob-size-14px) + 2px);
  font-weight: var(--medium-w);
}

.passenger span + span {
  color: var(--ui-5);
  font-family: var(--font-regular);
  font-size: var(--fmob-size-12px);
  font-weight: var(--regular-w);
}

main:global(.desktop) .passenger {
  row-gap: 0;
}

main:global(.desktop) .passenger > span {
  font-family: var(--font-medium);
  font-size: var(--f-size-16px);
  font-weight: var(--medium-w);
}

main:global(.desktop) .passenger span + span {
  font-family: var(--font-regular);
  font-size: var(--f-size-14px);
  font-weight: var(--regular-w);
}
