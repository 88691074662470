.container {
  display: flex;
  flex-direction: column;
}

ul.savedSearchesList {
  row-gap: 0.25rem;
  color: var(--primary-2);
  font-family: var(--font-medium);
  font-size: var(--fmob-size-16px);
  font-weight: var(--medium-w);
  margin-bottom: 0.42rem;
  margin-top: 1.25rem;
}

main:global(.desktop) ul.savedSearchesList {
  row-gap: 0rem;
  margin-bottom: 1.875rem;
  margin-top: 0;
  font-family: var(--font-medium);
  font-size: var(--f-size-16px);
  font-weight: var(--medium-w);
}

li.savedSearchItem {
  display: flex;
  flex-direction: row;
  grid-column-start: 2;
  column-gap: 1rem;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
}

main:global(.desktop) li.savedSearchItem {
  height: 3.125rem;
  border-radius: var(--corner-sm);
  padding: 0 1rem;
  column-gap: 1.25rem;
  align-items: center;
}

main:global(.desktop) li.savedSearchItem:hover {
  background-color: var(--ui-2);
}

main:global(.desktop) li.savedSearchItem > div {
  row-gap: 0;
}

li.savedSearchItem > div {
  row-gap: 0.12rem;
}

li.savedSearchItem p + p {
  color: var(--ui-5);
}

main:global(.desktop) li.savedSearchItem p + p {
  font-family: var(--font-regular);
  font-size: var(--f-size-16px);
  line-height: 1.375rem;
  font-weight: var(--regular-w);
}

li.savedSearchItem p > img {
  vertical-align: middle;
  padding: 0 0.38rem;
}

li.savedSearchItem p > span {
  padding: 0 0.25rem;
}

li.savedSearchItem > div {
  flex-basis: 100%;
}
