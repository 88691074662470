@keyframes slideInFromLeft {
  0% {
    right: -500px;
  }
  100% {
    right: 0;
  }
}

@keyframes slideOutToLeft {
  0% {
    right: 0;
  }
  100% {
    right: -500px;
  }
}

.card {
  background: #ffffff;
  border-style: solid;
  border-color: #e8edf1;
  border-width: 1px;
  height: fit-content;
  width: fit-content;
  padding: 20px;
  border-radius: 0px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  z-index: var(--zindex-high);
}

.sidebar.open {
  animation: slideInFromLeft var(--modal-anim-time) ease forwards;
}

.sidebar.close {
  animation: slideOutToLeft var(--modal-anim-time) ease forwards;
}

.card.withShadowSm {
  box-shadow: var(--booking-card-shadow);
}

.card.withShadowLg {
  box-shadow: 0px 8px 24px 0px rgba(49, 49, 49, 0.18), 0px 4px 8px 0px rgba(49, 49, 49, 0.16);
}

.card.roundCornerXSm {
  border-radius: var(--corner-sm);
}

.card.roundCornerSm {
  border-radius: var(--corner-md);
}

.card.roundCornerLg {
  border-radius: var(--corner-xl);
}
