div.frame {
  height: 4rem;
  padding: 0 1rem;
  border-style: solid;
  border-width: 0.0625rem;
  background-color: #e9eef24d;
  row-gap: 0.15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

main:global(.desktop) div.frame {
  row-gap: 0.25rem;
  padding: 0 1.25rem;
}

.bigText,
.smallText {
  color: var(--text-2);
}

label.bigText {
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
  font-size: calc(var(--fmob-size-14px) + 2px);
}

label.smallText {
  color: var(--text-2);
  font-family: var(--font-regular);
  font-size: calc(var(--fmob-size-10px) + 4px);
  font-weight: var(--regular-w);
}

label.smallText ~ input,
label.bigText ~ input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--primary-2);
  font-family: var(--font-semibold);
  font-size: calc(var(--fmob-size-14px) + 2px);
  font-weight: var(--semibold-w);
}

label.smallText ~ input:focus,
label.bigText ~ input:focus {
  outline-width: 0;
}

main:global(.desktop) label.smallText {
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
  font-size: var(--f-size-14px);
}

main:global(.desktop) label.smallText ~ input {
  font-family: var(--font-semibold);
  font-size: var(--f-size-18px);
  font-weight: var(--semibold-w);
}

div:global(.grid-row-multi) div.frame {
  background-color: var(--ui-1);
  border: transparent;
  margin: 0 0.5rem 0;
  height: 100%;
}

main:global(.desktop) :global(.results-header) div.frame {
  border: 1px solid var(--ui-4);
  height: 3rem;
  border-radius: 0rem var(--corner-sm) var(--corner-sm) 0rem;
  row-gap: 0rem;
  padding: 0 1rem;
}

main:global(.desktop) :global(.results-header) label.smallText ~ input {
  font-size: var(--f-size-16px);
}
