.container {
  display: flex;
  flex-direction: row;
}

span.radioText {
  color: var(--primary-2);
  font-family: var(--font-medium);
  font-size: calc(var(--fmob-size-14px) + 2px);
  font-weight: var(--medium-w);
}

main:global(.desktop) span.radioText {
  color: var(--primary-2);
  font-family: var(--font-medium);
  font-size: var(--f-size-16px);
  font-weight: var(--medium-w);
}