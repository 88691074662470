.button {
  padding: 0.8125rem 1rem; /*13px & 16px*/
  border: none;
  border-radius: var(--corner-md);
  cursor: pointer;
  text-align: center;
  word-break: keep-all;
  font-family: var(--font-bold);
  font-size: var(--fmob-size-16px);
  font-weight: var(--bold-w);
  color: #ffffff;
  user-select: none;
  -webkit-user-select: none;
  transition: all 0.15s;
  min-width: fit-content;
}

/* .button:focus {
  outline: 1px var(--ui-4) solid !important;
  transition: outline 0.15s;
} */

.button.primary {
  background-color: var(--primary-1);
}

.button.secondary {
  color: var(--primary-2);
  background-color: var(--ui-3);
}

.button.ternary {
  background-color: var(--primary-3);
}

.button.wide {
  width: 100%;
}

.button.disabled {
  background-color: var(--ui-3);
  color: var(--ui-4);
  cursor: default;
}

.button.shadow {
  box-shadow: 0px 4px 8px 0px rgba(37, 42, 49, 0.16);
}

.button.hover:hover.primary {
  background-color: var(--primary-btn-hover-color);
  transition: all 0.15s;
}

.button.hover:hover.secondary {
  background-color: var(--secondary-btn-hover-color);
  transition: all 0.15s;
}

.button.hover:hover.disabled {
  background-color: var(--ui-3);
  color: var(--ui-4);
  transition: all 0.15s;
}

.roundCornerSm {
  border-radius: var(--corner-sm);
}

.roundCornerMd {
  border-radius: var(--corner-md);
}

.roundCornerLg {
  border-radius: var(--corner-lg);
}
