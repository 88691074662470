div.search {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  /* grid-template-rows: header src dest start-date; */
  grid-template-areas:
  "header header"
  "src src"
  "dest dest"
  "start-date start-date";
}

.switchButton {
  position: absolute;
  grid-row-start: 2;
  grid-row-end: span 2;
  align-self: center;
  grid-column-start: 2;
  justify-self: end;
  border: none;
  background: none;
  padding: 1.25rem;
  z-index: var(--zindex-medium-low);
}

.formHeader {
  grid-area: header;
  display: flex;
  justify-content: space-between;
  margin: .25rem 0 .5rem;
  align-items: baseline;
  color: var(--primary-2);
  grid-column: span 2;
}

.formHeader button {
  font-family: var(--font-semibold);
  font-size: var(--fmob-size-16px);
  font-weight: var(--semibold-w);
  padding: 0;
}

.formHeader legend {
  font-family: var(--font-semibold);
  font-size: var(--fmob-size-16px);
  font-weight: var(--semibold-w);
}

.src {
  border-radius: var(--corner-sm) var(--corner-sm) 0rem 0rem;
  border-color: #bac7d5;
  grid-column: span 2;
  grid-area: src;
}
.dest {
  grid-column: span 2;
  grid-area: dest;
  border-color: transparent #bac7d5 #bac7d5;
}
.startDate {
  grid-column: span 2;
  grid-area: start-date;
  border-radius: 0rem 0rem var(--corner-sm) var(--corner-sm);
  border-color: transparent #bac7d5 #bac7d5;
}

.formHeader button {
  text-decoration: underline;
  border: none;
  background: none;
  text-underline-offset: 2px;
  text-decoration-skip-ink: none;
}
