.container {
  display: flex;
  flex-direction: column;
}

.container h2 {
  margin-top: 1.15rem;
  color: var(--primary-2);
  font-family: var(--font-medium);
  font-size: var(--fmob-size-18px);
  font-weight: var(--medium-w);
}

main:global(.desktop) .container h2 {
  margin-bottom: 1.25rem;
  margin-top: 0;
  font-family: var(--font-semibold);
  font-size: var(--f-size-18px);
  font-weight: var(--semibold-w);
}

.content {
  display: grid;
  grid-auto-rows: max-content;
}

.searchInput h1 {
  color: var(--primary-2);
  font-family: var(--font-semibold);
  font-size: var(--fmob-size-20px);
  font-weight: var(--semibold-w);
  margin-bottom: 0.75rem;
}

.searchInput {
  margin-bottom: 0.75rem;
}

.emptyResult {
  color: var(--primary-2);
  font-family: var(--font-medium);
  font-size: var(--fmob-size-16px);
  font-weight: var(--medium-w);
  line-height: 1.125rem;
  transition: all 200ms ease;
}

main:global(.desktop) .emptyResult {
  height: 9.38rem;
  line-height: 1.25rem;
  max-width: 25rem;
}
