.exit {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
}

.exit img {
  vertical-align: text-top;
}

.weekdays {
  color: var(--primary-2);
  font-family: var(--font-bold);
  font-size: var(--fmob-size-12px);
  font-weight: var(--bold-w);
  width: calc(100% + 2rem) !important;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  gap: 0.25rem;
  place-items: center;
  margin-top: 2.35rem;
  padding: 0 1rem 0.75rem;
  justify-self: center;
  box-shadow: 0rem 0.25rem 0.25rem -0.125rem rgba(37, 42, 49, 0.12);
  word-break: break-all;
}

.drawer {
  justify-self: center;
  border-top: 0.0625rem solid var(--ui-3);
  border-radius: var(--corner-sm) var(--corner-sm) 0rem 0rem;
  width: calc(100% + 2rem) !important;
  padding: 1.25rem 1rem 0;
}

.datepicker {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-content: stretch;
}

.startDate,
.endDate {
  max-height: 4rem;
  border-radius: var(--corner-sm);
  border-color: #bac7d5;
  overflow: hidden;
}

.startDate.selected,
.endDate.selected {
  border-color: var(--primary-1);
  background-color: var(--primary-tint-dark);
}

@keyframes textZoom {
  0% {
    font-size: var(--fmob-size-14px);
  }
  50% {
    font-size: 0.9375rem;
    color: var(--primary-1);
    font-family: var(--font-semibold);
    font-weight: var(--semibold-w);
  }
  100% {
    font-size: var(--fmob-size-14px);
  }
}

.endDate.small label {
  font-size: var(--fmob-size-14px);
  animation: textZoom 0.75s ease-in;
}

.fontclass {
  font-family: var(--font-semibold);
  font-size: calc(var(--fmob-size-16px) + 2px);
  font-weight: var(--semibold-w);
}
