.item-collapse {
  width: 100%;
  overflow: hidden;
  word-wrap: break-word;
  height: fit-content;
}
.item-collapse .item-collapse-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  background: none;
  border: none;
  font-family: var(--font-medium);
  font-size: var(--f-size-18px);
  font-weight: var(--medium-w);
  color: var(--primary-2);
  position: relative;
  transition: background-color 0.2s ease-in-out;
  text-align: left;
}
.item-collapse .item-collapse-title:focus-visible {
  background-color: var(--ui-2);
  outline: none;
  transition: background-color 0.2s ease-in-out;
}
.item-collapse button.item-collapse-title svg {
  flex-shrink: 0;
}
.item-collapse .item-collapse-content {
  width: 100%;
  padding: 0.5rem 0;
  font-size: var(--f-size-18px);
  line-height: 1.4rem;
}
.item-collapse .item-collapse-content b {
  font-weight: var(--bold-w);
  font-family: var(--font-bold);
}
.item-collapse .item-collapse-content a {
  text-decoration: underline dotted 1px;
  text-underline-offset: 2px;
}

main#faq-main-page {
  position: relative;
  background-color: var(--ui-1);
  flex-grow: 1;
}

main#faq-topics-page h1,
main#faq-main-page h1 {
  width: 100%;
  color: var(--primary-2);
  font-family: var(--font-bold);
  font-weight: var(--bold-w);
  font-size: var(--f-size-40px);
}
main#faq-main-page h1 {
  color: var(--ui-1);
}

main#faq-main-page h1 {
  margin-bottom: 1.5rem;
  text-align: center;
}

main#faq-main-page section {
  margin-top: 3.75rem;
}

main#faq-main-page.desktop .padded-view {
  position: relative;
  padding: 3.75rem calc(var(--main-page-side-pad) + 9.675rem) 6.5rem;
  margin: auto;
}

main#faq-main-page .faq-header-graphic {
  position: absolute;
  top: -60px;
  left: 0;
  height: 26.55rem;
  width: 100%;
}

main#faq-main-page div.faq-header-graphic::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-color: var(--primary-1);
  background-position: center;
  background-size: 100%;
}

.faq-signin-section h2,
.faq-common-q-section h2,
.faq-solve-yourself h2 {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--f-size-24px);
  margin-bottom: 0.875rem;
}
.faq-signin-section h2 {
  font-size: var(--f-size-20px);
  margin-bottom: 0.5rem;
}

/* FAQ SEARCH BLOCK */
main#faq-main-page section.faq-search {
  position: relative;
  margin-top: 1.875rem;
}
.faq-search-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
}
.faq-search-results {
  position: absolute;
  z-index: 10;
  top: 3rem;
  left: 0;
  width: 100%;
  padding: 1rem 1.25rem 1rem;
  background-color: white;
  box-shadow: 0px 8px 24px 0px rgba(49, 49, 49, 0.18), 0px 4px 8px 0px rgba(49, 49, 49, 0.16);
}
.faq-search-results li + li {
  border-top: 1px solid var(--ui-3);
}
.faq-search-results li a {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto;
  color: var(--primary-2);
  padding: 1rem 0.5rem;
  border-radius: var(--corner-sm);
  background-color: transparent;
  transition: background-color 0.4s;
}
.faq-search-results a:hover {
  background-color: var(--ui-2);
  transition: background-color 0.2s;
}
.faq-search-results li:hover,
.faq-search-results li:hover + li {
  border-top-color: transparent;
  transition: all 0.2s;
}
.faq-search-results.no-results {
  text-align: center;
  padding: 1.875rem 1.25rem;
  color: var(--text-3);
}
.faq-highlight {
  background-color: var(--primary-1-50);
}
.faq-result-topic {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
}
.faq-result-question {
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
}
.faq-result-answer {
  margin-top: 0.4rem;
  font-size: var(--f-size-14px);
}

.faq-result-answer,
.faq-result-question,
.faq-result-topic,
.faq-result-other {
  grid-column: 2;
}
.faq-result-icon {
  grid-column: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

/* FAQ SIGNIN SECTION */
.faq-signin-card {
  width: 100%;
  display: flex;
  padding: 1.875rem;
}
.faq-signin-card .faq-signin-card-content {
  flex-grow: 1;
}
.faq-signin-card button {
  margin-top: 1.25rem;
}

/* MAIN FAQ TOPICS */
.faq-cats {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  gap: 1.25rem;
}
.faq-cats li,
.faq-cats li a {
  width: 100%;
  display: flex;
}
.faq-cats .faq-cat {
  overflow-x: hidden;
  width: 100%;
  min-height: 9.0625rem;
  display: flex;
  flex-direction: column;
  transition: all 0.9s;
  height: 100%;
}
.faq-cats .faq-cat:hover {
  background-color: var(--ui-2);
  transition: background-color 0.2s;
}
.faq-cats .faq-cat h3 {
  margin-bottom: 0.5rem;
  display: inline-flex;
  column-gap: 0.25rem;
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--f-size-18px);
}
.faq-cats .faq-cat p {
  line-height: 1.125rem;
  margin-bottom: 0.625rem;
}
.faq-cats li a,
.faq-cats li a:hover {
  color: var(--primary-2);
}
.faq-cats .faq-cat .faq-cat-bottom {
  flex-grow: 1;
  display: flex;
}
.faq-cats .faq-cat .faq-cat-bottom svg {
  margin-top: auto;
  transition: stroke 0.4s;
}
.faq-cats .faq-cat:hover .faq-cat-bottom svg path {
  stroke: var(--primary-1);
  transition: stroke 0.4s;
}
.faq-cats .faq-cat svg {
  flex-shrink: 0;
}

/* COMMON QUESTIONS */
.faq-common-q-list {
  border-radius: var(--corner-md);
  box-shadow: var(--booking-card-shadow);
  background-color: white;
  overflow: hidden;
}
.faq-common-q-list li {
  position: relative;
}
.faq-common-q-list li + li::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 1.875rem;
  border-top: 1px solid var(--ui-3);
  transition: margin 0.4s ease-in-out;
}
.faq-common-q-list li:has(.item-collapse.closed .item-collapse-title:hover) + li::after,
.faq-common-q-list li:has(.item-collapse.closed .item-collapse-title:hover)::after {
  margin: 0;
  transition: margin 0.4s ease-in-out;
}

.faq-topic-q-inner .item-collapse-title,
.faq-common-q-inner .item-collapse-title {
  padding: 1.5rem 1.875rem;
}
.faq-topic-q-inner.closed .item-collapse-title:hover,
.faq-common-q-inner.closed .item-collapse-title:hover {
  background-color: var(--primary-tint);
  transition: background-color 0.2s ease-in-out;
}
.faq-topic-q-inner .item-collapse-content,
.faq-common-q-inner .item-collapse-content {
  padding: 0.375rem 1.875rem 1.875rem;
}

/* TOPICS PAGE */
main#faq-topics-page #faq-topics-breadcrumbs {
  margin-bottom: 3.125rem;
  column-gap: 0.5rem;
}

main#faq-topics-page.desktop .padded-view {
  position: relative;
  padding: 1.875rem calc(var(--main-page-side-pad) + 8.675rem) 6.5rem;
  margin: auto;
}

.faq-topic-section {
  margin-top: 3.93rem;
}

.faq-topic-section h2 {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--f-size-24px);
  margin-bottom: 1rem;
}
.faq-topic-section h2 a {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.faq-topic-question-list li + li {
  margin-top: 0.75rem;
}

.faq-topic-question-list li {
  background-color: white;
  position: relative;
}

.faq-topic-q-inner {
  box-shadow: var(--booking-card-shadow);
}

.faq-topic-anchor {
  color: transparent;
  transition: color 0.15s ease-in-out;
  margin-left: -0.25rem;
}
.faq-topic-section h2:hover .faq-topic-anchor {
  color: var(--ui-4);
}

/* MOBILE CSS */
main#faq-main-page.mobile,
main#faq-topics-page.mobile {
  position: relative;
  width: 100%;
  padding: 2rem 1rem 3.5rem;
}
main#faq-main-page.mobile div.faq-header-graphic {
  height: 23.5rem;
}
main#faq-main-page.mobile div.faq-header-graphic::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: inherit;
  background-color: var(--primary-1);
  background-position: center;
  background-size: auto;
}
main#faq-topics-page.mobile > h1,
main#faq-main-page.mobile > h1 {
  font-size: var(--f-size-24px);
}
main#faq-main-page.mobile > h1,
main#faq-main-page.mobile > section {
  position: relative;
}
main#faq-main-page.mobile .faq-cats {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
main#faq-main-page.mobile .faq-cats .faq-cat {
  min-height: 7.0625rem;
}
main#faq-main-page.mobile .faq-search {
  margin-top: 0.75rem;
}
main#faq-main-page.mobile .faq-signin-section {
  margin-top: 2.5rem;
}
main#faq-topics-page.mobile h2,
main#faq-main-page.mobile h2 {
  font-size: var(--f-size-18px);
}
main#faq-main-page.mobile h3 {
  font-size: var(--f-size-16px);
}
main#faq-main-page.mobile .faq-signin-card {
  position: relative;
}
main#faq-main-page.mobile .faq-signin-card svg {
  position: absolute;
  height: 50%;
  bottom: 1rem;
  right: 0rem;
}
main#faq-main-page.mobile .faq-signin-card-content {
  text-wrap: balance;
  word-break: break-word;
}
main#faq-main-page.mobile .faq-solve-yourself,
main#faq-main-page.mobile .faq-common-q-section {
  margin-top: 2.5rem;
}

.mobile button.item-collapse-title,
.mobile p.item-collapse-content {
  font-size: var(--f-size-16px);
}

@media screen and (min-width: 900px) and (max-width: 1200px) {
  main#faq-main-page .faq-solve-yourself ul {
    grid-template-columns: 1fr 1fr;
  }
}
main#faq-topics-page.mobile {
  background: none;
  padding-top: 1rem;
}
main#faq-topics-page.mobile #faq-topics-breadcrumbs {
  margin-bottom: 1.5rem;
}
main#faq-topics-page.mobile .faq-topic-section {
  margin-top: 1rem;
}
