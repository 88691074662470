.scroll-calendar {
  padding-top: 0.75rem;
  overflow-y: scroll;
}

.mobile-datepicker {
  color: var(--primary-2);
  font-family: var(--font-semibold);
  font-size: var(--fmob-size-14px);
  font-weight: var(--semibold-w);
}

.mobile-datepicker .month .month-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 0.25rem;
  margin: 1.25rem 0 0.35rem;
  font-size: calc(var(--fmob-size-14px) + 2px);
}

.mobile-datepicker .month .month-title span {
  order: 1;
}

.mobile-datepicker .month ul {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  gap: 0.25rem;
  place-items: stretch;
}

.mobile-datepicker .month ul.date li {
  cursor: pointer;
  border-radius: var(--corner-sm);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.mobile-datepicker .month ul.date li:before {
  content: "";
  padding: 42.5% 0;
}

.mobile-datepicker .month ul.date li.disabled {
  pointer-events: none;
}
.mobile-datepicker .month ul.date li.disabled span {
  color: var(--text-3);
  cursor: not-allowed;
  font-family: var(--font-regular);
  font-size: var(--fmob-size-14px);
  font-weight: var(--regular-w);
}

.mobile-datepicker .month ul.date li.today {
  color: var(--primary-1);
  font-family: var(--font-semibold);
  font-size: var(--fmob-size-14px);
  font-weight: var(--semibold-w);
}

.mobile-datepicker .month ul.date li.range {
  background: var(--ui-2);
}

.mobile-datepicker .month ul.date li.active {
  background: var(--primary-1);
  color: var(--ui-1);
}

.mobile-datepicker .month ul.date li.vis-hidden {
  opacity: 0;
  filter: alpha(opacity=0);
  visibility: hidden;
  pointer-events: none;
}
