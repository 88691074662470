.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  color: var(--primary-2);
  font-family: var(--font-semibold);
  font-style: var(--font-style);
  font-weight: var(--semibold-w);
  line-height: var(--line-height);
  font-size: var(--f-size-14px);
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  padding: 0;
}

.icon + span {
  margin-left: 0.3rem;
}

nav button:last-child .icon + span {
  margin: 0;
}

.button:last-of-type .icon + span {
  margin: 0;
}
