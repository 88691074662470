.container {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.radio {
  align-items: center;
  column-gap: 0.75rem;
  color: var(--primary-2);
  cursor: pointer;
}

.desktop.radio {
  column-gap: 0.625rem;
}

.radio input[type="radio"] {
  flex-shrink: 0;
  justify-content: center;
  appearance: none;
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  cursor: pointer;
  border: 0.0625rem var(--ui-4) solid;
  border-radius: 50%;
  outline: none;
  background-color: var(--ui-1);
}

.radio input[type="radio"]:checked {
  background-color: var(--primary-1);
  border: none;
  position: relative;
}

.radio input[type="radio"]:checked:before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background: var(--ui-1);
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}