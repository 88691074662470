.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.875rem;
}
.mobile.loading {
  display: flex;
  flex-direction: column;
  padding: 1.875rem 1rem;
  background-color: var(--ui-1);
}
.mobile.loading h2 {
  align-self: flex-start;
}

.desktop.container {
  display: flex;
  flex-direction: column;
  padding: 1.875rem 1.875rem 2.5rem;
}
.desktop .paymentTypes {
  display: flex;
  flex-direction: column;
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  font-size: var(--f-size-16px);
  border: 1px var(--ui-3) solid;
  border-radius: var(--corner-sm);
  box-shadow: 0rem 0.0625rem 0.125rem 0rem rgba(241, 244, 247, 1);
}
.desktop .paymentType {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0.875rem 1.25rem;
  transition: all 0.15s;
  -webkit-transition: all 0.15s;
}
.desktop .paymentType + .paymentType {
  border-top: 1px var(--ui-3) solid;
}
.desktop .paymentType:hover {
  background-color: #f6f7f9;
  transition: all 0.15s;
  -webkit-transition: all 0.15s;
}
.desktop .paymentType .details {
  display: flex;
  align-items: center;
  column-gap: 0.625rem;
}
.desktop .paymentType .details .icon,
.desktop .selectedType .header .icon {
  background-color: #fff;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 1.875rem;
  flex-shrink: 0;
  border: 1px var(--ui-3) solid;
  border-radius: var(--corner-sm);
}

.desktop .selectedType .header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 1.25rem;
  border: 1px var(--ui-3) solid;
  border-radius: var(--corner-sm);
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  font-size: var(--f-size-16px);
  box-shadow: 0rem 0.0625rem 0.125rem 0rem rgba(241, 244, 247, 1);
}
.desktop .selectedType .header > *:first-of-type {
  display: flex;
  align-items: center;
  column-gap: 0.625rem;
}
.desktop .selectedType .header .changeBtn {
  border: none;
  padding: 0;
  background: none;
  color: var(--primary-1);
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  font-size: var(--f-size-16px);
}
.desktop .selectedType .subtypes {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.625rem;
  padding: 0 4.25rem;
}
.desktop .selectedType .paymentVendor {
  width: 7.5rem;
  height: 3.75rem;
  background-color: rgba(244, 247, 248, 0.5);
  outline: 1px solid var(--ui-2);
  border-radius: var(--corner-md);
  cursor: pointer;
}
.desktop .selectedType .slot {
  width: 7.5rem;
  /* visibility: collapse; */
  height: 0;
}
.desktop .selectedType .paymentVendor.active {
  outline-color: var(--primary-1);
  outline-width: 2px;
}
.desktop .selectedType .paymentVendor .icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.desktop .note {
  display: inline-flex;
  align-items: baseline;
  column-gap: 0.5rem;
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  font-size: var(--f-size-14px);
}
.desktop .terms,
.desktop .dealsOptOut {
  padding: 0.875rem 1.25rem;
  border: 1px var(--ui-3) solid;
  border-radius: var(--corner-sm);
}
.desktop .terms {
  margin-bottom: 1rem;
  margin-top: 1.25rem;
}

.desktop .terms input[type="checkbox"] {
  align-self: baseline;
}

.desktop div.terms span.text,
.desktop div.dealsOptOut span.text {
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
  font-size: var(--f-size-14px);
  line-height: 1.125rem;
}

.lockIcon {
  flex-shrink: 0;
  display: flex;
  width: 1.25rem;
  height: 1.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--primary-tint-dark);
}

/* MOBILE CSS */
.mobile.container {
  display: flex;
  flex-direction: column;
  padding: 1.875rem 1rem;
  background-color: var(--ui-1);
}
.mobile h2 {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--fmob-size-20px);
  padding-bottom: 1.25rem;
}
.mobile .paymentTypes {
  display: flex;
  flex-direction: column;
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  font-size: var(--fmob-size-16px);
  border: 1px var(--ui-3) solid;
  border-radius: var(--corner-sm);
  box-shadow: 0rem 0rem 0.125rem 0rem rgba(79, 94, 113, 0.12),
    0rem 0.125rem 0.25rem 0rem rgba(79, 94, 113, 0.11),
    0rem 0.25rem 0.5rem 0rem rgba(79, 94, 113, 0.1);
}
.mobile .paymentType {
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 1rem;
  transition: all 0.15s;
  -webkit-transition: all 0.15s;
}
.mobile .paymentType + .paymentType {
  border-top: 1px var(--ui-3) solid;
}
.mobile .paymentType:hover {
  background-color: #f6f7f9;
  transition: all 0.15s;
  -webkit-transition: all 0.15s;
}
.mobile .paymentType .details {
  display: flex;
  align-items: center;
  column-gap: 0.75rem;
}

.mobile .paymentType .details .icon,
.mobile .selectedType .header .icon {
  background-color: #fff;
  display: grid;
  align-items: center;
  justify-content: center;
  width: 2.375rem;
  height: 1.875rem;
  flex-shrink: 0;
  border: 1px var(--ui-3) solid;
  border-radius: var(--corner-sm);
}

.mobile .selectedType .header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.875rem 1.25rem;
  border: 1px var(--ui-3) solid;
  border-radius: var(--corner-sm);
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  font-size: var(--fmob-size-16px);
  box-shadow: 0rem 0rem 0.125rem 0rem rgba(79, 94, 113, 0.12),
    0rem 0.125rem 0.25rem 0rem rgba(79, 94, 113, 0.11),
    0rem 0.25rem 0.5rem 0rem rgba(79, 94, 113, 0.1);
}
.mobile .selectedType .header > *:first-of-type {
  display: flex;
  align-items: center;
  column-gap: 0.625rem;
}
.mobile .paymentType .details .icon + div,
.mobile .selectedType .header .icon + span {
  line-break: anywhere;
}
.mobile .selectedType .header .changeBtn {
  border: none;
  padding: 0;
  background: none;
  color: var(--primary-1);
  font-family: var(--font-bold);
  font-weight: var(--bold-w);
  font-size: var(--fmob-size-16px);
}
.mobile .selectedType .subtypes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.mobile .selectedType .paymentVendor {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 4.375rem;
  background-color: rgba(244, 247, 248, 0.5);
  outline: 1px solid var(--ui-2);
  border-radius: var(--corner-md);
  cursor: pointer;
}
.mobile .selectedType .paymentVendor.active {
  outline-color: var(--primary-1);
  outline-width: 2px;
}
.mobile .selectedType .paymentVendor .icon {
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
}

.mobile .note {
  display: inline-flex;
  align-items: baseline;
  column-gap: 0.5rem;
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  font-size: var(--fmob-size-14px);
}
.mobile .terms {
  margin-bottom: 1rem;
  margin-top: 1.25rem;
  border-radius: var(--corner-md);
}

.mobile .terms > div,
.mobile .dealsOptOut > div {
  column-gap: 0.625rem;
}

.mobile input[type="checkbox"] {
  align-self: baseline;
}

.mobile div.terms span.text,
.mobile div.dealsOptOut span.text {
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
  font-size: var(--fmob-size-14px);
  line-height: 1.125rem;
}

.mobile .selectedType .paymentVendor .icon img {
  max-width: 7.5rem;
  max-height: 100%;
  height: auto;
  width: auto;
}

.paymentTypes.warn {
  border-color: #d21c1c;

}

.terms.warn,
.mobile .terms.warn input[type="checkbox"] {
  border-color: #d21c1c;
}
.mobile .terms.warn {
  border-radius: var(--corner-md);
  background-color: #d21c1c1a;
}
.mobile .terms.warn input[type="checkbox"] + span * {
  color: #d21c1c;
  text-decoration-color: #d21c1c;
}

button.docbtn {
  background: none;
  border: none;
  padding: 0;
}
.desktop button.docbtn b,
.mobile button.docbtn b {
  text-decoration: underline solid var(--primary-2) 1px;
  text-underline-offset: 1px;
}

.transfer,
.cash {
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
  font-size: 0.9375rem;
}

.transfer h3,
.cash h3 {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--f-size-18px);
  margin-bottom: 0.25rem;
}

.transfer ul,
.cash ul {
  margin-left: 1.75rem;
  list-style: disc;
  line-height: normal;
  padding-bottom: 1rem;
}

.transfer li + li,
.cash li + li {
  margin-top: 0.25rem;
}

.transfer .smb,
.cash .smb {
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
}

.paymentTypeNote {
  font-size: 13px;
  color: var(--text-4);
}
.selectedType.desktop .name > span,
.details.desktop .name > span {
  margin-right: 0.5rem;
}
.selectedType.mobile .name,
.details.mobile .name {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
}