.desktop-datepicker {
  color: var(--primary-2);
  font-family: var(--font-semibold);
  font-size: var(--f-size-14px);
  font-weight: var(--semibold-w);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  column-gap: 2.06rem;
}

.desktop-datepicker .month .month-header {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  margin-bottom: 1.25rem;
}

.desktop-datepicker .month .month-header.previous {
  justify-items: start;
}

.desktop-datepicker .month .month-header p {
  grid-column-start: 2;
  grid-column-end: span 1;
  justify-self: center;
}

.desktop-datepicker .month .month-header.next {
  justify-items: end;
}

.desktop-datepicker .month .month-header button {
  border: none;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.53rem;
  background-color: var(--ui-2);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.desktop-datepicker .month button.previous {
  order: -1;
  filter: drop-shadow(0px 2px 4px rgba(214, 218, 220, 0.6));
}

.desktop-datepicker .month button.next {
  order: 1;
  filter: drop-shadow(0px 2px 4px rgba(214, 218, 220, 0.6));
}

.desktop-datepicker .month .month-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 0.25rem;
  align-items: center;
  font-family: var(--font-semibold);
  font-size: var(--f-size-16px);
  font-weight: var(--semibold-w);
}

.desktop-datepicker .month .month-title span {
  order: 1;
}

.desktop-datepicker .month .weekdays {
  color: var(--primary-2);
  font-family: var(--font-semibold);
  font-size: var(--f-size-14px);
  font-weight: var(--semibold-w);
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  gap: 0.25rem;
  place-items: center;
  justify-self: center;
  margin-bottom: 1.25rem;
  word-break: break-all;
  width: 100%;
}

.desktop-datepicker .month .weekdays li {
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.desktop-datepicker .month ul.date {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: auto;
  gap: 0.25rem;
  place-items: stretch;
}

.desktop-datepicker .month ul.date li {
  cursor: pointer;
  border-radius: var(--corner-sm);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.desktop-datepicker .month ul.date li:before {
  content: "";
  padding: 50% 0;
}

.desktop-datepicker .month ul.date li.disabled {
  pointer-events: none;
}
.desktop-datepicker .month ul.date li.disabled span {
  color: var(--text-3);
  cursor: not-allowed;
  font-family: var(--font-regular);
  font-size: var(--f-size-14px);
  font-weight: var(--regular-w);
}

.desktop-datepicker .month ul.date li.today {
  color: var(--primary-1);
  font-family: var(--font-semibold);
  font-size: var(--f-size-14px);
  font-weight: var(--semibold-w);
}

.desktop-datepicker .month ul.date li.range {
  background: var(--ui-2);
}

.desktop-datepicker .month ul.date li.potential:hover {
  background: var(--ui-2);
}

.desktop-datepicker .month ul.date li.active {
  background: var(--primary-1);
  color: var(--ui-1);
}

.desktop-datepicker .month ul.date li.vis-hidden,
.desktop-datepicker .month ul.date li.visible-hidden {
  opacity: 0;
  filter: alpha(opacity=0);
  /* visibility: hidden; */
  pointer-events: all;
  cursor: default;
}
