div.frame {
  height: 3.5rem;
  padding: 0 1.25rem;
  border: solid 0.0625rem #bac7d5;
  border-radius: var(--corner-sm);
  background-color: rgba(233, 238, 242, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: text;
}

div.frame.active {
  border-color: var(--primary-1);
}

main:global(.desktop) div.frame {
  flex-direction: column;
  row-gap: 0.25rem;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--ui-1);
  border-width: 1px;
  border-color: transparent;
  border-right-color: var(--ui-3);
  height: 5rem;
  width: 100%;
  pointer-events: all;
}

.bigText {
  color: var(--text-2);
}

label.bigText,
label.smallText,
div.frame {
  cursor: text;
}

label.bigText {
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
  font-size: calc(var(--fmob-size-14px) + 2px);
  display: flex;
  flex-direction: row;
  column-gap: 0.6rem;
  align-items: baseline;
  width: 100%;
}

label.bigText input {
  width: 100%;
  background-color: transparent;
  border: none;
  color: var(--primary-2);
  caret-color: var(--primary-2);
  font-family: var(--font-semibold);
  font-size: calc(var(--fmob-size-14px) + 2px);
  font-weight: var(--semibold-w);
}

label.bigText input::placeholder {
  color: var(--ui-5);
  font-family: var(--font-regular);
  font-size: calc(var(--fmob-size-14px) + 2px);
  font-weight: var(--small-text-regular-font-weight, 400);
}

main:global(.desktop) label.bigText,
main:global(.desktop) label.smallText {
  pointer-events: none;
}

main:global(.desktop) label.bigText {
  font-family: var(--font-regular);
  font-size: var(--f-size-18px);
  font-weight: var(--regular-w);
  transition: font-size 100ms ease-out;
}

main:global(.desktop) label.smallText input {
  font-family: var(--font-semibold);
  font-size: var(--f-size-18px);
  font-weight: var(--semibold-w);
  width: 100%;
  background-color: transparent;
  border: none;
  color: var(--primary-2);
  caret-color: var(--primary-2);
  padding: 0;
  margin-top: 0.25rem;
  pointer-events: all;
}

main:global(.desktop) label.smallText {
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
  font-size: var(--f-size-14px);
  color: var(--text-2);
  transition: font-size 100ms ease-out;
}

main:global(.desktop) span.smallText {
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
  font-size: var(--f-size-14px);
  color: var(--text-2);
  transition: font-size 100ms ease-out;
  position: absolute;
  right: 1.56rem;
}

label.bigText input:focus {
  outline-width: 0;
}

button.icon {
  border: none;
  background: none;
  padding: 0.5rem;
  margin: -0.5rem -0.813rem -0.5rem -0.5rem;
}

button.icon img {
  text-align: center;
  vertical-align: middle;
}

main:global(.desktop) :global(.results-header) div.frame {
  height: 3rem;
  border-right-width: 0;
  border-color: var(--ui-4);
  padding: 0 1rem;
}

main:global(.desktop) :global(.results-header) div.frame:first-child {
  border-radius: var(--corner-sm) 0rem 0rem var(--corner-sm);
}

main:global(.desktop) :global(.results-header) label.bigText,
main:global(.desktop) :global(.results-header) label.smallText input {
  font-size: var(--f-size-16px);
}

/** multi form specific **/
main:global(.desktop) :global(.grid-row-multi) div.frame {
  grid-row-start: 1;
  grid-column: 1 / 2;
  border-radius: var(--corner-md) 0rem 0rem var(--corner-md);
}

main:global(.desktop) :global(.grid-row-multi) div.frame ~ div.frame {
  grid-row-start: 1;
  grid-column: 2 / 3;
  border-radius: 0rem;
}

main:global(.desktop) :global(.results-header) label.smallText input {
  margin-top: 0rem;
}