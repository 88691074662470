.container {
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
}

.passengers {
  border-radius: var(--corner-sm);
  border-color: #bac7d5;
}

form.container + button {
  text-align: right;
  padding: 0.25rem 0 0.25rem;
  color: var(--primary-1);
  font-family: var(--font-bold);
  font-size: var(--fmob-size-16px);
  font-weight: var(--bold-w);
}

form.container + button,
.back {
  border: none;
  background: none;
}

.fontclass {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--fmob-size-18px);
}

.back {
  padding: 0;
  text-align: left;
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--fmob-size-14px);
  color: var(--primary-2);
}

/* SF MODAL BUTTONS ON RESULTS PAGE */
.buttonRow {
  display: flex;
  justify-content: space-between;
}
.buttonRow button:last-of-type {
  width: 7.5rem;
}
.buttonRow button:only-child {
  flex-basis: 100%;
}
/* SF MODAL BUTTONS ON RESULTS PAGE --- END */
