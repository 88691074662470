.desktop.field {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  border: var(--ui-4) 1px solid;
  border-radius: var(--corner-sm);
  overflow: auto;
}
.desktop.field textarea,
.desktop.field input {
  border: none;
  height: 2.75rem;
  caret-color: var(--primary-2);
  width: 100%;
  font-size: 0.9375rem;
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  padding: 0 1rem;
  color: var(--primary-2);
}
.desktop.field textarea {
  height: 8rem;
  padding: 1rem;
  resize: none;
}
.desktop .field textarea::placeholder,
.desktop.field input::placeholder {
  color: var(--ui-5);
}
.desktop.field.invalid {
  border-color: #d21c1c;
}
.desktop.field.active {
  border-color: var(--primary-1);
}

label.desktop {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.desktop .label {
  display: flex;
  margin-bottom: 0.3125rem;
  font-size: 0.9375rem;
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  white-space: nowrap;
  align-items: center;
  pointer-events: none;
}
.desktop .label svg {
  margin-right: 0.34rem;
  height: 0.9375rem;
}
.desktop .label svg path {
  min-width: 0.9rem;
  min-height: 0.9rem;
}

/* DROPDOWN ELEMENT CSS */
.desktop.dropdown select.field {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  align-items: center;
  border: var(--ui-4) 1px solid;
  border-radius: var(--corner-sm);
  padding: 0 1rem;
  width: 100%;
  font-size: 0.9375rem;
  height: 2.75rem;
  color: var(--primary-2);
  background: transparent;
  background-image: url("src/assets/img/dropdownArrow.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
}

.desktop.dob select:invalid,
.desktop.dropdown select.field:invalid {
  color: var(--ui-5);
}

.desktop.dob select option,
.desktop.dropdown select.field .option {
  color: var(--primary-2);
}
.desktop.dropdown select.field.invalid {
  border-color: #d21c1c;
}
.desktop.dropdown select.field.active {
  border-color: var(--primary-1);
}
.desktop select.field .option,
.desktop.dropdown select.field {
  font-size: 0.9375rem;
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
}

/* DOB INPUT */
.desktop.dob {
  grid-template-columns: 1fr 2px 1.8fr 2px 1fr;
}
.desktop.dob > * {
  font-size: 0.9375rem;
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  color: var(--primary-2);
}
.desktop.dob .hrBorder {
  height: 2rem;
  width: 1px;
  background-color: var(--ui-4);
}
.desktop.dob.invalid .hrBorder {
  background-color: #d21c1c;
}
.desktop.dob.active .hrBorder {
  background-color: var(--primary-1);
}
.desktop.dob select {
  border: none;
  padding: 0 1rem;
  height: 100%;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  background: transparent;
  background-image: url("src/assets/img/dropdownArrow.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
  color: var(--primary-2);
}

/* MOBILE CSS */
.mobile.field {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  border: var(--ui-4) 1px solid;
  border-radius: var(--corner-sm);
  overflow: auto;
}
.mobile.field textarea,
.mobile.field input {
  border: none;
  height: 2.75rem;
  caret-color: var(--primary-2);
  width: 100%;
  font-size: var(--fmob-size-16px);
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  padding: 0 1rem;
  color: var(--primary-2);
}
.mobile .field textarea::placeholder,
.mobile.field input::placeholder {
  color: var(--ui-5);
}
.mobile.field.invalid {
  border-color: #d21c1c;
}
.mobile.field.active {
  border-color: var(--primary-1);
}

label.mobile {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}
.mobile .label {
  display: flex;
  margin-bottom: 0.375rem;
  font-size: 0.9375rem;
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  white-space: nowrap;
  align-items: center;
}
.mobile .label svg {
  margin-right: 0.34rem;
  height: 0.9375rem;
}
.mobile .label svg path {
  min-width: 0.9rem;
  min-height: 0.9rem;
}

/* DROPDOWN ELEMENT CSS */
.mobile.dropdown select.field {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  align-items: center;
  border: var(--ui-4) 1px solid;
  border-radius: var(--corner-sm);
  padding: 0 1rem;
  width: 100%;
  font-size: 0.9375rem;
  height: 2.75rem;

  background: transparent;
  background-image: url("src/assets/img/dropdownArrow.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
  color: var(--primary-2);
}
.mobile.dropdown select.field:invalid,
.mobile.dropdown select:invalid {
  color: var(--ui-5);
}
.mobile.dropdown select.field .option {
  color: var(--primary-2);
}
.mobile.dropdown select.field.invalid {
  border-color: #d21c1c;
}
.mobile.dropdown select.field.active {
  border-color: var(--primary-1);
}
.mobile select.field .option,
.mobile.dropdown select.field {
  font-size: var(--fmob-size-16px);
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
}

/* DOB INPUT */
.mobile.dob {
  grid-template-columns: 1fr 2px 1.8fr 2px 1fr;
}
.mobile.dob > * {
  font-size: var(--fmob-size-16px);
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  color: var(--primary-2);
}
.mobile.dob .hrBorder {
  height: 2rem;
  width: 1px;
  background-color: var(--ui-4);
}
.mobile.dob.invalid .hrBorder {
  background-color: #d21c1c;
}
.mobile.dob.active .hrBorder {
  background-color: var(--primary-1);
}
.mobile.dob select {
  border: none;
  padding: 0 1rem;
  height: 2.75rem;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  background: transparent;
  background-image: url("src/assets/img/dropdownArrow.svg");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 1rem);
  background-position-y: 50%;
}

.label .info {
  display: inline-grid;
}
.label .labelText,
.label .info {
  pointer-events: all;
}
.tooltipInner {
  position: relative;
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto 1fr;
}
.tooltipInner .close {
  position: absolute;
  right: 0;
  top: 3px;
  grid-row: 1 / 1;
  padding: 5px;
  margin: -5px;
  cursor: pointer;
  align-self: flex-start;
}
.tooltipInner .close svg {
  display: block;
  width: 10px;
  height: 10px;
}
.tooltipText {
  grid-row: 1 / span 2;
  display: flex;
  flex-direction: column;
  row-gap: 0.75rem;
  line-height: 1rem;
}
.desktop .tooltipText {
  font-family: var(--font-semibold);
  font-size: var(--f-size-14px);
  font-weight: var(--semibold-w);
}
.mobile .tooltipText {
  font-family: var(--font-semibold);
  font-size: 0.9375rem;
  font-weight: var(--semibold-w);
}

.eye {
  margin-right: 0.625rem;
  background: none;
  border: none;
  padding: 0;
}
.eye svg {
  display: block;
}

.dropdown select:disabled {
  color: var(--ui-4) !important;
}

.dropdown.simple {
  background-color: white;
}
.dropdown.simple select {
  height: 2.85rem !important;
}
.field.simple:focus-within,
.dropdown.simple select:focus {
  border: 1px solid var(--primary-1) !important;
  transition: all 0.15s;
}

.simple textarea,
.simple input,
.simple select {
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  font-size: var(--f-size-12px);
}

.simple *::placeholder {
  color: var(--ui-4) !important;
}
