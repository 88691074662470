.container {
  display: flex;
  flex-direction: row;
}

.counter {
  align-items: center;
  column-gap: 0.75rem;
}

.counter .count {
  min-width: 0.65rem;
  text-align: center;
  color: var(--primary-2);
  font-family: var(--font-medium);
  font-size: var(--fmob-size-16px);
  font-weight: var(--medium-w);
}

main:global(.desktop) .counter {
  font-size: 1.125rem;
  line-height: 1.1875rem;
}

.counter button:disabled {
  cursor: default;
}