.menu {
  width: 100%;
  font-family: var(--font-semibold);
  font-style: var(--font-style);
  font-weight: var(--semibold-w);
  line-height: var(--line-height);
  font-size: var(--f-size-14px);
  background-color: var(--nav-bar-color);
  box-shadow: var(--nav-bar-shadow);
  z-index: var(--zindex-medium);
}

.menu.mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.125rem 3%;
  column-gap: 0.5rem;
  z-index: var(--zindex-medium);
  box-shadow: 0rem 0.25rem 0.25rem -0.125rem rgba(37, 42, 49, 0.12);
}

.menu.desktop {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  line-height: 1rem;
}

.menu.desktop div:global(.padded-view) {
  width: 100%;
  padding: 1.25rem var(--main-page-side-pad);
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr auto;
  white-space: nowrap;
  column-gap: 1.5rem;
}

.mobile .logo {
  width: 7.5rem;
}

.logo {
  width: 7.3125rem;
  margin-right: 0.5125rem;
  cursor: pointer;
}

.menuLeft {
  column-gap: 1.85rem;
  display: flex;
  align-items: center;
}

.desktop .menuLeft {
  overflow: hidden;
}

.desktop .menuLeft a:not(a:first-of-type) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.menuLeft a:first-of-type {
  display: flex;
}

/* NavLink default */
.menu a {
  color: var(--passive-link-color);
  transition: all 0.15s;
}

.menu a:active {
  color: var(--primary-link-color);
  opacity: 0.7;
}

.menu a:hover {
  color: var(--primary-link-color);
  transition: all 0.15s;
}

a.active {
  color: var(--active-link-color);
  border-bottom: var(--active-link-line);
  padding-bottom: var(--active-link-line-offset);
  margin-top: var(--active-link-line-offset);
}

.menuRight {
  justify-self: end;
  column-gap: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menuRight button {
  color: var(--passive-link-color);
}
.menuRight button svg {
  color: var(--passive-link-color);
}
ой
.desktop .menuRight {
  min-width: 100%;
}

.mobile .menuRight {
  column-gap: 1rem;
}
