.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

section.container {
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 1rem;
}

section.container > * {
  width: 100%;
}

.search > h1 {
  color: var(--primary-2);
  font-family: var(--font-bold);
  font-size: var(--fmob-size-24px);
  font-weight: var(--bold-w);
  margin-bottom: 0.25rem;
}

.search > h1.results {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
}

.fontclass {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--fmob-size-16px);
}

.desktop div:global(.padded-view) {
  padding: 4.25rem var(--main-page-side-pad);
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  height: fit-content;
  position: relative;
}

.desktop.search > * > h1 {
  font-family: var(--font-bold);
  font-size: var(--f-size-40px);
  font-weight: var(--bold-w);
  color: var(--ui-1);
  margin-bottom: 0.88rem;
}

.flexbox {
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 16.075rem;
  left: initial;
  pointer-events: none;
}

.flexbox.results {
  top: 6.15rem;
  left: initial;
}

.dropdownGrid {
  display: grid;
  grid-template-columns: repeat(2, 15rem) 22.5rem 20.67% 6.5rem auto;
  align-items: stretch;
}
.oneway .dropdownGrid {
  grid-template-columns: repeat(2, 17.14rem) 15.98rem 24.5% 2.5rem auto;
}

.dropdown {
  padding: 1.87rem 1.25rem 2.25rem;
  width: 25rem;
  max-height: 30.5rem;
  z-index: var(--zindex-medium);
  overflow-y: scroll;
  overflow-x: hidden;
  pointer-events: all;
}

.dropdown::-webkit-scrollbar {
  display: none;
}

.dropdown.src-location {
  grid-column-start: 1;
  grid-column-end: span 6;
}

.dropdown.dest-location {
  grid-column-start: 2;
  grid-column-end: span 5;
}

.dropdown.src-location,
.dropdown.dest-location {
  min-width: 25rem;
  width: max-content;
}

.dropdown.start-date,
.dropdown.end-date {
  display: grid;
  grid-column-start: 3;
  grid-column-end: span 1;
  justify-self: center;
  width: 40.375rem;
  min-height: 21.25rem;
  max-height: 23.25rem;
}

.dropdown.passengers {
  padding: 1.56rem 1.25rem 1.25rem;
  width: 21.75rem;
  height: fit-content;
  grid-column-start: -4;
  justify-self: end;
}

.noSFButton.flexbox {
  display: grid;
  position: absolute;
  width: calc(var(--main-page-content-max-width) - var(--main-page-side-pad) * 2);
}
.noSFButton .dropdownGrid {
  grid-template-columns: repeat(2, minmax(19%, 17rem)) minmax(30%, 26rem) minmax(
      auto,
      32%
    );
}
.noSFButton.oneway .dropdownGrid {
  grid-template-columns: repeat(2, 25%) 19.7% auto;
}
.noSFButton .dropdown.dest-location {
  grid-column-start: 2;
}
.noSFButton .dropdown.passengers {
  grid-column-start: -2;
}

@media only screen and (max-width: 1439px) and (min-width: 991px) {
  .flexbox,
  .flexbox.results {
    display: grid;
    position: absolute;
    width: calc(100% - var(--main-page-side-pad) * 2);
  }

  .dropdownGrid {
    grid-template-columns: repeat(2, 19%) 30% 19.43% 0.5rem auto;
  }

  .oneway:not(.noSFButton) .dropdownGrid {
    grid-template-columns: repeat(2, 21.85%) 21.38% 22.34% 0.5rem auto;
  }

  .results .dropdownGrid {
    grid-template-columns: repeat(2, 19%) 28.5% 20.9% 0.5rem auto;
  }
  .results.oneway .dropdownGrid {
    grid-template-columns:
      repeat(2, 21.85%)
      21.38%
      22.34% 0.5rem auto;
  }

  .dropdown.start-date,
  .dropdown.end-date {
    grid-column-start: 3;
    grid-column-end: span 1;
    margin: 0 calc(-100vw * 0.25);
  }

  .oneway .dropdown.start-date {
    grid-column-start: -4;
    justify-self: end;
    margin: 0 0;
  }

  .noSFButton.oneway.flexbox {
    display: grid;
    position: absolute;
    width: calc(100% - var(--main-page-side-pad) * 2);
  }

  .noSFButton:not(.oneway) .dropdownGrid {
    grid-template-columns: repeat(2, 21.65%) minmax(30%, auto) auto;
  }

  .noSFButton.oneway .dropdownGrid {
    grid-template-columns: repeat(2, 25%) 21.38% auto;
  }

  .noSFButton .dropdown.start-date,
  .noSFButton .dropdown.end-date {
    grid-column-start: 2;
    grid-column-end: span 3;
    justify-self: center;
  }

  .noSFButton.oneway .dropdown.start-date,
  .noSFButton.oneway .dropdown.end-date {
    grid-column-start: 3;
    grid-column-end: span 1;
    justify-self: center;
  }

  .dropdownGrid .passengers {
    grid-column-start: -4;
    justify-self: end;
  }

  .noSFButton .dropdown.passengers {
    grid-column-start: 1;
    grid-column-end: -1;
    justify-self: end;
  }
  .noSFButton.oneway .dropdown.passengers {
    grid-column-start: 1;
    grid-column-end: span 4;
  }

  form[name="main-search-form-multi"] + * .dropdownGrid {
    grid-template-columns: repeat(3, 32.36%) 2.25rem !important;
  }

  form[name="main-search-form-multi"] + * .dropdownGrid .passengers {
    grid-column-start: 2;
    margin-right: 0.5rem;
    justify-self: end !important;
  }

  form[name="main-search-form-multi"] + * .dropdownGrid .start-date {
    margin: 0;
    justify-self: end !important;
  }
}

form[name="main-search-form-multi"] + * .dropdownGrid {
  display: grid;
  grid-template-rows: repeat(7, 5.75rem);
  grid-template-columns: repeat(3, 25rem) 2.25rem;
  align-items: stretch;
}

form[name="main-search-form-multi"] + .results .dropdownGrid {
  grid-template-rows: repeat(7, 3.75rem);
}

form[name="main-search-form-multi"] + * .dropdown.src-location {
  grid-column-start: 1;
}

form[name="main-search-form-multi"] + * .dropdown.dest-location {
  grid-column-start: 2;
}
form[name="main-search-form-multi"] + * .dropdown.start-date {
  grid-column-start: 3;
  justify-self: end;
}
form[name="main-search-form-multi"] + * .dropdown.passengers {
  width: 24rem;
  justify-self: center;
  grid-column-start: 2;
}

form[name="main-search-form-multi"] + .results .dropdownGrid .passengers {
  min-width: 96%;
}

form[name="main-search-form-multi"] + * .dropdown.src-location.row0 {
  grid-row-start: 1;
}
form[name="main-search-form-multi"] + * .dropdown.src-location.row1 {
  grid-row-start: 2;
}
form[name="main-search-form-multi"] + * .dropdown.src-location.row2 {
  grid-row-start: 3;
}
form[name="main-search-form-multi"] + * .dropdown.src-location.row3 {
  grid-row-start: 4;
}
form[name="main-search-form-multi"] + * .dropdown.src-location.row4 {
  grid-row-start: 5;
}
form[name="main-search-form-multi"] + * .dropdown.src-location.row5 {
  grid-row-start: 6;
}
form[name="main-search-form-multi"] + * .dropdown.src-location.row6 {
  grid-row-start: 7;
}

form[name="main-search-form-multi"] + * .dropdown.dest-location.row0 {
  grid-row-start: 1;
}
form[name="main-search-form-multi"] + * .dropdown.dest-location.row1 {
  grid-row-start: 2;
}
form[name="main-search-form-multi"] + * .dropdown.dest-location.row2 {
  grid-row-start: 3;
}
form[name="main-search-form-multi"] + * .dropdown.dest-location.row3 {
  grid-row-start: 4;
}
form[name="main-search-form-multi"] + * .dropdown.dest-location.row4 {
  grid-row-start: 5;
}
form[name="main-search-form-multi"] + * .dropdown.dest-location.row5 {
  grid-row-start: 6;
}
form[name="main-search-form-multi"] + * .dropdown.dest-location.row6 {
  grid-row-start: 7;
}

form[name="main-search-form-multi"] + * .dropdown.start-date.row0 {
  grid-row-start: 1;
}
form[name="main-search-form-multi"] + * .dropdown.start-date.row1 {
  grid-row-start: 2;
}
form[name="main-search-form-multi"] + * .dropdown.start-date.row2 {
  grid-row-start: 3;
}
form[name="main-search-form-multi"] + * .dropdown.start-date.row3 {
  grid-row-start: 4;
}
form[name="main-search-form-multi"] + * .dropdown.start-date.row4 {
  grid-row-start: 5;
}
form[name="main-search-form-multi"] + * .dropdown.start-date.row5 {
  grid-row-start: 6;
}
form[name="main-search-form-multi"] + * .dropdown.start-date.row6 {
  grid-row-start: 7;
}

form[name="main-search-form-multi"] + * .dropdown.passengers.row0 {
  grid-row-start: 2;
}
form[name="main-search-form-multi"] + * .dropdown.passengers.row1 {
  grid-row-start: 3;
}
form[name="main-search-form-multi"] + * .dropdown.passengers.row2 {
  grid-row-start: 4;
}
form[name="main-search-form-multi"] + * .dropdown.passengers.row3 {
  grid-row-start: 5;
}
form[name="main-search-form-multi"] + * .dropdown.passengers.row4 {
  grid-row-start: 6;
}
form[name="main-search-form-multi"] + * .dropdown.passengers.row5 {
  grid-row-start: 7;
}
form[name="main-search-form-multi"] + * .dropdown.passengers.row6 {
  grid-row-start: 8;
}
