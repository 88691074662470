body:has(dialog[open]) {
  overflow: hidden;
}

.body {
  overflow: hidden;
}

dialog.fullscreen {
  display: grid;
  grid-template: 1fr / 1fr;
  min-width: 100%;
  min-height: 100%;
  height: 100%;
  margin: 0;
  padding: 1.87rem 1rem;
  border: none;
  background: var(--ui-1);
  z-index: var(--zindex-highest);
}

dialog.halfscreen {
  /* position: absolute; */
  inset-block-start: unset;
  min-width: 100%;
  height: fit-content;
  margin: 0;
  padding: 1.87rem 1rem 1.56rem;
  border: none;
  background: var(--ui-1);
  border-radius: var(--corner-xl) var(--corner-xl) 0rem 0rem;
  z-index: var(--zindex-highest);
}

dialog::backdrop {
  /* display: none; */
  background: rgb(0, 0, 0, 0.5);
  animation: opacity-down 200ms linear forwards;
}

dialog[open]::backdrop {
  animation: opacity-up 200ms linear forwards;
}

.backdrop {
  position: fixed;
  inset: 0;
  width: 100%;
  /* height: 100vh; */
  z-index: var(--zindex-high);
  /* background: rgb(0, 0, 0, 0.5); */
}

.fadein {
  animation: opacity-up var(--backdrop-anim-time) linear forwards;
}

.fadeout {
  animation: opacity-down var(--backdrop-anim-time) linear forwards;
}

@keyframes opacity-up {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes opacity-down {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0;
  }
}

@media print {
  body:has(dialog[open]) {
    overflow: visible;
  }

  dialog::backdrop {
    display: none;
    background: none;
    animation: none;
  }

  dialog[open]::backdrop {
    animation: none;
  }

  .backdrop,
  .body,
  dialog.fullscreen,
  dialog.halfscreen {
    position: absolute;
    display: contents;
    height: 100%;
    min-width: 100%;
    overflow: visible;
    visibility: hidden;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
  }
}
