.container {
  position: fixed;
  inset: 0;
  z-index: var(--zindex-highest);
  min-height: 100vh;
  width: 100%;
  padding: 0;
  display: grid;
}

.content {
  position: relative;
  align-items: center;
  text-align: center;
  margin: auto;
}
