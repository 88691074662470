/* TOP INFORMATION LINE CSS */
.information {
  border: 1px var(--tinted-border-color) solid;
  border-left: 0.335rem solid var(--primary-1) !important;
  border-radius: var(--corner-md);
  display: flex;
  column-gap: 0.625rem;
  background-color: var(--primary-tint-darker);
}
.information svg {
  flex-shrink: 0;
}

.desktop.information {
  padding: 1rem 1.43rem;
  align-items: flex-start;
}
.desktop.information span {
  font-size: 0.9375rem;
  line-height: 1.2rem;
  font-family: var(--font-medium);
  font-weight: var(--medium-w);
  color: var(--primary-2);
}
.information span b {
  font-family: var(--font-bold);
  font-weight: var(--bold-w);
}
/* TOP INFORMATION LINE CSS -- END */

/* TOP INFORMATION LINE CSS */
.mobile.information {
  padding: 0.75rem 1rem;
  align-items: flex-start;
  /* margin-bottom: 1.25rem; */
}
.mobile.information span {
  font-size: 0.9375rem;
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
  color: var(--primary-2);
  line-height: 1.125rem;
}
/* TOP INFORMATION LINE CSS -- END */

.warning {
  border-color: #f5ebc1;
  border-left: 0.335rem solid var(--warning) !important;
  border-radius: var(--corner-md);
  display: flex;
  column-gap: 0.625rem;
  background-color: #fff7d1;
}
