.container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.directions {
  cursor: pointer;
}

.directions li {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid var(--ui-3);
  border-width: 0.0625rem;
  word-break: break-all;
}

.directions button {
  padding: 0.625rem;
  font-family: var(--font-medium);
  font-size: 0.9375rem;
  font-weight: var(--semibold-w);
  color: var(--primary-2);
  user-select: none;
  -webkit-user-select: none;
  width: 100%;
  height: 3.25rem;
}

.directions button span {
  justify-content: center;
  word-break: break-word;
  padding-left: 0.25rem;
}

.directions .selected button span img {
  filter: invert(0.75) brightness(40%) sepia(100%) hue-rotate(178deg) saturate(8)
    contrast(0.85);
}
.directions .leftmost {
  border-radius: var(--corner-sm) 0rem 0rem var(--corner-sm);
}

.directions .rightmost {
  border-radius: 0 var(--corner-sm) var(--corner-sm) 0;
}

.directions .middle {
  border-left-color: transparent;
  border-right-color: transparent;
}

.directions .selected {
  border-color: var(--primary-1);
}

.directions .selected button {
  color: var(--primary-1);
}

.directions.desktop {
  width: max-content;
  column-gap: 1.25rem;
}

.directions.desktop li {
  border: none;
}

.directions.desktop li :not(.results) input[type="radio"] {
  background-color: var(--radio-accent-bg-unchecked);
  border-color: var(--ui-3);
}

.directions.desktop li :not(.results) input[type="radio"]:checked {
  background-color: var(--radio-accent-bg);
  border: var(--radio-accent-border);
  position: relative;
}

.directions.desktop li :not(.results) input[type="radio"]::before {
  content: "";
  opacity: 0;
  width: 0;
  height: 0;
}

.directions.desktop li :not(.results) input[type="radio"]:checked::before {
  content: "";
  opacity: 1;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background: var(--radio-accent-inner);
  border-radius: 50%;
  box-sizing: border-box;
}

.directions.desktop span.radioText {
  color: var(--ui-1);
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--f-size-16px);
}

.directions.desktop .results span.radioText {
  color: var(--direction-results-color);
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
  font-size: var(--f-size-16px);
}

main:global(.desktop) .directions li {
  width: auto;
}
