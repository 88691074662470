.parent .child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  min-height: 3.4375rem;
  cursor: pointer;
}

.border {
  border: solid #e8edf1;
  border-width: 0 0 1px;
}

.border.none {
  border: solid transparent;
  border-width: 0 0 1px;
}

.link {
  color: var(--primary-2);
  font-family: var(--font-semibold);
  font-size: var(--f-size-16px);
  font-weight: var(--semibold-w);
}

.link + .link {
  margin-top: 0.33rem;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  row-gap: 0.625rem;
  padding-bottom: 1.125rem;
}
