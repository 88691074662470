.avatar {
  width: 100%;
  height: 100%;
  min-width: 1rem;
  min-height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  container-type: size;
  background-color: var(--ui-4);
  border-radius: 50%;
  overflow: hidden;
}

.letter {
  font-size: 60cqw;
}