.desktop div:global(.padded-view) {
  width: 100%;
  margin: auto;
  padding: 0 var(--main-page-side-pad);
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

section.mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  row-gap: 0.5rem;
  margin: 0 auto 3.75rem;
}

section.desktop {
  margin-top: 4.375rem;
  display: block;
}

.saleImage img {
  border-radius: var(--corner-md);
  max-height: 13.75rem;
  width: 100%;
}

.mobile .saleImage,
.mobile .saleImage a {
  max-width: 20.5rem;
}
.mobile .saleImage img {
  width: 100%;
}

.saleImageGroup {
  column-gap: 1rem;
  width: 100%;
}

.saleImageGroup img {
  width: 100%;
  max-width: 25rem;
  max-height: 14.375rem;
  height: auto;
  border-radius: var(--corner-xl);
}

@keyframes loadingGradient {
  0% {
    background-position-x: 20%;
  }
  100% {
    background-position-x: -150%;
  }
}

.imgError {
  border-radius: var(--corner-xl);
  height: 12rem;
  width: 20.5rem;
  background-color: var(--ui-3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgError .frame {
  width: 40%;
  opacity: 0.2;
}

.imgError,
.imgError .frame {
  background: var(--ui-1);
  background: linear-gradient(
    250deg,
    rgba(233, 238, 242, 0.35) 0%,
    rgba(235, 238, 240, 0.85) 50%,
    rgba(233, 238, 242, 0.35) 100%
  );
  background-size: 250% 100%;
  animation: loadingGradient 1s linear infinite;
  -webkit-animation: loadingGradient 1s linear infinite;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
