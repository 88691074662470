.desktop.deals div:global(.padded-view) {
  padding: 4.25rem var(--main-page-side-pad);
  margin: auto;
}
.imgError {
  height: 100%;
  background-color: var(--ui-3);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.6;
}
.imgError + div p,
.imgError + div h2 {
  color: var(--ui-5);
}
.imgError .frame {
  width: 50%;
  opacity: 0.5;
}

.deals h1 {
  font-family: var(--font-bold);
  font-size: 1.375rem;
  font-weight: var(--bold-w);
  margin: 0 auto 1.25rem;
  text-align: center;
}

.deals p.desc {
  font-family: var(--font-regular);
  font-size: var(--fmob-size-16px);
  font-weight: var(--regular-w);
  margin-bottom: 1.5625rem;
  text-align: center;
  width: 95%;
  line-height: 1.125rem;
}

.desktop .filterGroup {
  justify-content: center;
}
.filterGroup {
  font-family: var(--font-semibold);
  font-size: var(--fmob-size-14px);
  font-weight: var(--semibold-w);
  align-self: stretch;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  padding: 0.5rem 0 1.25rem;
  column-gap: 0.5rem;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.filterGroup::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.filterGroup > button:last-of-type {
  margin-right: 1rem;
}

.filterGroup button {
  text-wrap: nowrap;
  white-space: nowrap;
  height: 2.75rem;
}

.filterButtonFont {
  font-family: var(--font-semibold);
  font-size: var(--fmob-size-16px);
  font-weight: var(--semibold-w);
}
.desktop .filterButtonFont {
  background-color: var(--ui-1);
  border: 1px solid var(--ui-3);
  height: fit-content;
}
.desktop .filterButtonFont.active {
  background-color: var(--primary-3);
  border: 1px solid var(--primary-3);
}
.mobile .dealsList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(3, 1fr);
  gap: 0.5rem;
  margin-bottom: 1.25rem;
}
.desktop .dealsList {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 1rem;
  margin-bottom: 1.75rem;
}

.dealsList li {
  position: relative;
  aspect-ratio: 1;
}

.dealsList img {
  width: 100%;
}

.dealsList li {
  position: relative;
}

.dealsList a {
  display: block;
  height: 100%;
}

.dealsList img {
  border-radius: var(--corner-md);
  height: 100%;
  object-fit: cover;
  filter: brightness(0.85);
}

.destination {
  position: absolute;
  left: 10%;
  bottom: 12%;
}

.destination h2,
.destination p {
  color: var(--ui-1);
}

.destination h2 {
  padding-bottom: 8%;
  font-family: var(--font-bold);
  font-size: var(--fmob-size-20px);
  font-weight: var(--bold-w);
}

.destination p {
  font-family: var(--font-semibold);
  font-size: var(--fmob-size-16px);
  font-weight: var(--semibold-w);
}
