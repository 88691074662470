.container {
  display: flex;
  flex-direction: row;
}

.content {
  display: grid;
  grid-template-rows: max-content max-content 1fr;
}

.passengers ul {
  padding: 0.87rem 0;
  flex-direction: column;
  row-gap: 1.12rem;
}

main:global(.desktop) .passengers ul {
  padding: 1.25rem 0;
  row-gap: 0.9375rem;
}

.passengers h1 {
  color: var(--primary-2);
  font-family: var(--font-semibold);
  font-size: calc(var(--fmob-size-18px) + 2px);
  font-weight: var(--semibold-w);
}

.cabin {
  margin-top: 1rem;
}

main:global(.desktop) .cabin {
  margin-top: 0.625rem;
}

.cabin h2 {
  color: var(--primary-2);
  font-family: var(--font-medium);
  font-size: calc(var(--fmob-size-16px) + 2px);
  font-weight: var(--medium-w);
}

.cabin ul {
  padding: 1.25rem 0;
  flex-direction: column;
  row-gap: 1rem;
}

main:global(.desktop) .cabin ul {
  padding: 1.25rem 0;
  flex-direction: column;
  row-gap: 0.625rem;
}

main:global(.desktop) .passengers h1,
main:global(.desktop) .cabin h2 {
  color: var(--primary-2);
  font-family: var(--font-semibold);
  font-size: var(--f-size-18px);
  font-weight: var(--semibold-w);
}

.cabin + button {
  align-self: end;
  min-height: 3.5rem;
}

main:global(.desktop) .cabin + div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

main:global(.desktop) .cabin + div button {
  width: 6.25rem;
  height: 2.5rem;
  padding: 0;
  margin-top: 0.56rem;
  font-size: var(--f-size-16px);
}

.fontclass {
  font-family: var(--font-semibold);
  font-size: calc(var(--fmob-size-16px) + 2px);
  font-weight: var(--semibold-w);
}
