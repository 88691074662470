.footer-mobile footer .footer-view {
  margin-top: 1rem !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: 0 !important;
  padding: 1rem !important;
}
.footer-mobile footer .footer-view .footer-social div,
.footer-mobile footer .footer-view .footer-social div a {
  width: 1.875rem;
  height: 1.875rem;
}
.footer-mobile footer .footer-view .footer-social,
.footer-mobile footer .footer-view .footer-logo img {
  max-height: 1.5rem !important;
}
.footer-mobile footer .footer-view .footer-social {
  margin-bottom: 1.5rem !important;
}
.footer-mobile footer .footer-view .footer-column {
  flex-basis: 100%;
}
.footer-mobile footer .footer-heading {
  margin-top: 0 !important;
  padding: 1rem 0 !important;
  font-size: var(--f-size-14px);
  font-weight: var(--bold-w);
  letter-spacing: 0.01rem;
}
.footer-mobile footer .footer-heading.collapsed {
  border-bottom: 1px solid var(--ui-3);
}
.footer-mobile footer .footer-heading::after {
  content: "";
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  background-image: url("/src/assets/img/collapseArrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  right: 1rem;
  margin-top: 3px;
}
.footer-mobile footer .footer-heading.collapsed::after {
  background-image: url("/src/assets/img/expandArrow.svg");
}
.footer-mobile footer .footer-links {
  font-size: var(--f-size-16px);
  margin: 0.75rem 0 !important;
  row-gap: 1rem !important;
}
.footer-mobile footer .footer-heading.collapsed + .footer-links {
  display: none;
}
.footer-mobile footer .footer-links a:hover {
  color: var(--primary-link-color) !important;
}

.footer-notice-logos {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 4rem;
  padding-bottom: 1rem;
}

.footer-notice-logos.desktop {
  column-gap: 2rem;
  margin-top: 0;
  padding-right: 5rem;
  justify-content: flex-end;
}

.footer-notice-logos > img {
  height: 50px;
}

.footer-notice-logos.desktop > img {
  height: 3.3rem;
  width: auto;
}

.footer-notice {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  margin-left: -2rem;
  border-radius: var(--corner-md);
  background-color: var(--ui-3);
}

.footer-notice:hover {
  background-color: var(--ui-4);
}

.footer-notice-text {
  position: absolute;
  bottom: 3rem;
  right: 0;
  background-color: var(--primary-2);
  border-radius: var(--corner-md);
  color: white;
  padding: 1rem;
  max-height: 7rem;
  width: 80vw;
  overflow: hidden;
}

.footer-notice-text .inner {
  padding: 0.5rem;
  overflow: auto;
  max-height: 5rem;
  max-width: 80vw;
  text-align: left;
  scrollbar-width: thin;
  overflow-y: scroll;
}

.desktop .footer-notice-text {
  width: 30rem;
}
.desktop .footer-notice-text .inner {
  max-width: 30rem;
}
