.breadcrumbs {
  display: flex;
  align-items: baseline;
  column-gap: 0.85%;
  justify-content: flex-start;
  cursor: default;
}
.step {
  color: var(--ui-5);
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: normal;
}
.step:hover {
  text-decoration-line: underline;
}
.step.active {
  font-family: var(--font-bold);
  font-weight: var(--bold-w);
}
.step.active,
.step.disabled {
  color: var(--primary-2);
  text-decoration-line: none;
  cursor: default;
  /* pointer-events: none; */
}
.arrow {
  flex-shrink: 0;
}
