.exit {
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
  padding: .75rem;
  margin: -0.75rem;
  border-radius: var(--corner-sm);
  padding-bottom: 0.25rem;
}

.exit:hover {
  background-color: var(--ui-3);
  transition: background-color 0.15s;
}

.exit svg {
  vertical-align: text-top;
}

.mobile.exit svg {
  height: 0.625rem;
  width: 0.625rem;
}
