.switch {
}

.switchButton {
  grid-row-start: 1;
  grid-row-end: span 2;
  align-self: center;
  grid-column-start: 2;
  justify-self: end;
  padding: 1.25rem;
  padding-left: 0;
  z-index: var(--zindex-medium-low);
  border: none;
  background: none;
}

main:global(.desktop) .switchButton {
  padding: 0.25rem;
  grid-row: 1 / 2;
  grid-column: 1 / span 2;
  justify-self: center;
  align-self: center;
}

main:global(.desktop) .switchButton svg {
  height: 1.875rem;
  width: 1.875rem;
  cursor: pointer;
  display: block;
}

main:global(.desktop) .switchButton svg rect + rect {
  stroke: var(--ui-3);
  stroke-opacity: 1;
}

main:global(.desktop) :global(.results-header) .switchButton {
  background: none;
  width: fit-content;
}

main:global(.desktop) :global(.results-header) .switchButton svg {
  height: 1.6rem;
  width: 1.6rem;
}

main:global(.desktop) :global(.results-header) .switchButton svg rect + rect {
  stroke: #bad1f7;
}

.switchButton svg path,
.switch svg path {
  transform: rotate(0deg);
  transform-origin: center;
  transition: none;
}

.switchButton.anim svg path,
.switch.anim svg path {
  transform: rotate(180deg);
  transition: all 200ms linear;
}
