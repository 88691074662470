div.frame {
  height: 4rem;
  padding: 0 1rem;
  border-style: solid;
  border-width: 0.0625rem;
  background-color: rgba(233, 238, 242, 0.3);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  pointer-events: all;
}

div.frame label {
  pointer-events: none;
}

.dateItem {
  display: flex;
  flex-direction: column;
  row-gap: 0.15rem;
  flex-grow: 1;
}

main:global(.desktop) .dateItem {
  row-gap: 0.25rem;
}

.bigText,
.smallText {
  color: var(--text-2);
}

label.bigText {
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
  font-size: calc(var(--fmob-size-14px) + 2px);
  transition: font-size 100ms ease-out;
}

label.smallText {
  color: var(--text-2);
  font-family: var(--font-regular);
  font-size: calc(var(--fmob-size-10px) + 4px);
  font-weight: var(--regular-w);
  transition: font-size 100ms ease-out;
}

label.smallText ~ input,
label.bigText ~ input {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0;
  color: var(--primary-2);
  font-family: var(--font-semibold);
  font-size: calc(var(--fmob-size-14px) + 2px);
  font-weight: var(--semibold-w);
}

label.smallText ~ input:focus,
label.bigText ~ input:focus {
  outline-width: 0;
}

button.icon {
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  margin: -0.5rem;
}

button.icon svg path {
  fill:#7d8998;
}

main:global(.desktop) div.frame {
  padding: 0 0.9rem 0 1.25rem;
  background-color: var(--ui-1);
  border-width: 1px;
  border-color: transparent;
  border-right-color: var(--ui-3);
  height: 5rem;
  width: 100%;
  grid-row-start: 1;
}

main:global(.desktop) button.icon {
  padding: 0.5rem;
  margin: -0.5rem;
}

main:global(.desktop) button.icon svg path {
  fill: var(--ui-5);
}

main:global(.desktop) label.smallText {
  font-family: var(--font-regular);
  font-weight: var(--regular-w);
  font-size: var(--f-size-14px);
}

main:global(.desktop) label.bigText {
  font-family: var(--font-regular);
  font-size: var(--f-size-18px);
  font-weight: var(--regular-w);
}

main:global(.desktop) label.bigText ~ input,
main:global(.desktop) label.smallText ~ input {
  font-family: var(--font-semibold);
  font-size: var(--f-size-18px);
  font-weight: var(--semibold-w);
}

main:global(.desktop) :global(.grid-row-multi) div.frame {
  border-radius: 0rem var(--corner-md) var(--corner-md) 0;
}

main:global(.desktop) :global(.results-header) :global(.grid-row-multi) div.frame {
  border-right-width: 1px;
  border-radius: 0rem var(--corner-sm) var(--corner-sm) 0rem;
}

main:global(.desktop) div.frame,
main:global(.desktop) div.frame * {
  cursor: pointer;
}

.disabled {
  cursor: default;
}

main:global(.desktop) :global(.results-header) div.frame {
  height: 3rem;
  border-right-width: 0;
  border-color: var(--ui-4);
  padding: 0 1rem;
}

main:global(.desktop) :global(.results-header) label.bigText,
main:global(.desktop) :global(.results-header) label.smallText ~ input {
  font-size: var(--f-size-16px);
}

main:global(.desktop) :global(.results-header) .dateItem {
  row-gap: 0rem;
}
