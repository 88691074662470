form.search {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, minmax(3.4375rem, max-content));
  grid-auto-rows: 1fr;
}

.roundtrip {
  grid-template-areas:
    "src src"
    "dest dest"
    "start-date end-date"
    "passengers passengers"
    ". ."
    "search-button search-button";
}

.oneway {
  grid-template-areas:
    "src src"
    "dest dest"
    "start-date start-date"
    "passengers passengers"
    ". ."
    "search-button search-button";
}

.src {
  grid-area: src;
  border-radius: var(--corner-sm) var(--corner-sm) 0rem 0rem;
  border-color: #bac7d5;
}
.dest {
  grid-area: dest;
  border-color: transparent #bac7d5 #bac7d5;
}
.startDate {
  grid-area: start-date;
  border-color: transparent #bac7d5 #bac7d5;
}
.endDate {
  grid-area: end-date;
  border-color: transparent #bac7d5 #bac7d5 transparent;
}
.passengers {
  grid-area: passengers;
  border-radius: 0rem 0rem var(--corner-sm) var(--corner-sm);
  border-color: transparent #bac7d5 #bac7d5;
}

form.search:not(.desktop) > button:last-of-type {
  grid-area: search-button;
  margin-top: 0.25rem;
  min-height: 3.5rem;
}

/* SF MODAL BUTTONS ON RESULTS PAGE */
form.search:not(.desktop) > button.back {
  grid-column-start: 1;
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--fmob-size-14px);
  color: var(--primary-2);
}

form.search:not(.desktop) > button.back + button:last-of-type {
  grid-column-start: 2;
  justify-self: end;
  width: 7.5rem;
}
form.search:not(.desktop) > button.back,
form.search:not(.desktop) > button.back + button:last-of-type {
  grid-row-start: 5;
  grid-row-end: 5;
  margin-top: 1.88rem;
  align-self: center;
}
/* SF MODAL BUTTONS ON RESULTS PAGE --- END */

.desktop.oneway,
.desktop.roundtrip {
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  grid-auto-rows: auto;
  align-items: stretch;
}

.desktop.roundtrip {
  grid-template-columns:
    repeat(2, minmax(19%, 15rem))
    repeat(2, minmax(14.25%, 11.25rem))
    20% 0.5rem auto;
}

.desktop.oneway {
  grid-template-columns:
    repeat(2, minmax(19%, 17.14rem))
    minmax(17.75%, 15.98rem)
    22.9% 0.5rem 0rem auto;
}

.desktop .passengers,
.desktop.search .passengers + .searchBtn {
  grid-row-start: 1;
}

.desktop .passengers,
.desktop.search:not(.results) .passengers + .searchBtn button {
  border-width: 1px;
  border-color: transparent;
  height: 5rem;
  width: 100%;
}

.desktop .src,
.desktop .dest {
  grid-row-start: 1;
}

.desktop .src {
  border-radius: var(--corner-sm) 0rem 0rem var(--corner-sm);
  grid-column-start: 1;
  grid-column-end: 2;
}
.desktop .dest {
  border-radius: 0rem;
  grid-column-start: 2;
  grid-column-end: 3;
}

.desktop .startDate {
  grid-column-start: 3;
  grid-column-end: 4;
}

.desktop .endDate {
  grid-column-start: 4;
  grid-column-end: 5;
}

.desktop .passengers {
  background-color: var(--ui-1);
  border-right-color: transparent;
  border-radius: 0rem var(--corner-md) var(--corner-md) 0rem;
  grid-column-start: 5;
  grid-column-end: 6;
}

.desktop.oneway .passengers {
  grid-column-start: 4;
  grid-column-end: 5;
}

.desktop .endDate div,
.desktop .startDate div {
  padding: 1.25rem 0.75rem;
  margin: -1.25rem -0.75rem;
}

form.desktop.search .passengers + .searchBtn {
  grid-column: -2 / -1;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

form.desktop.search .passengers + .searchBtn button {
  background-color: var(--search-button-color);
  border-right-color: transparent;
  text-align: center;
  margin: 0;
}

form.desktop.search .passengers + .searchBtn button:hover {
  background-color: var(--search-button-hover-color);
}

.desktop.noSFButton .passengers + div,
form.desktop.search .passengers > .searchBtn + div {
  grid-row-start: 2;
  grid-column-start: 1;
  justify-self: start;
  text-wrap: nowrap;
  margin-top: 1.25rem;
}

form.desktop.search .passengers + .searchBtn + div {
  grid-row-start: 2;
  grid-column-start: 1;
  margin-top: 1.25rem;
}

.desktop.noSFButton .passengers + div + div,
form.desktop.search .passengers + .searchBtn + div + div {
  justify-self: end;
  grid-column: 2 / -1;
  grid-row-start: 2;
  text-wrap: nowrap;
  margin-top: 1.25rem;
}

.fontclass {
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--fmob-size-18px);
}

.desktop .fontclass {
  font-family: var(--font-bold);
  font-size: var(--f-size-18px);
  font-weight: var(--bold-w);
}

span.checkbox2 b {
  color: #003580;
}

.desktop span.checkbox2,
.desktop span.checkbox1,
.desktop span.checkbox2 b {
  color: var(--ui-1);
  font-family: var(--font-semibold);
  font-weight: var(--semibold-w);
  font-size: var(--f-size-16px);
}

.desktop.results {
  margin: 0.5rem 0 0.75rem;
  transition: all 1s ease;
}

form.desktop.results .passengers + .searchBtn button {
  height: 3rem;
  border-radius: var(--corner-sm);
  font-size: 1rem;
  background-color: var(--primary-1);
  width: 100%;
  visibility: visible;
}

form.desktop.results .passengers + .searchBtn button:hover {
  background-color: var(--primary-btn-hover-color);
}

.desktop.results .searchBtn button.hideBtn {
  visibility: hidden;
  width: 100%;
}

.desktop.roundtrip.noSFButton {
  transition: all 1s ease;
  transition-delay: 0.25s;
  grid-template-columns:
    repeat(2, minmax(21.65%, 17rem))
    repeat(2, minmax(17.1%, 13rem))
    22.5% 0rem 0fr;
}

.desktop.oneway.noSFButton {
  transition: all 1s ease;
  transition-delay: 0.25s;
  grid-template-columns:
    repeat(2, minmax(auto, 25%))
    minmax(17.75%, 24%)
    26% 0rem 0rem 0rem;
}

.desktop.noSFButton .src {
  grid-row-start: 1;
  grid-column: 1 / 2;
}

.desktop.noSFButton .dest {
  grid-row-start: 1;
  grid-column: 2 / 3;
}

.desktop.noSFButton .startDate {
  grid-row-start: 1;
  grid-column: 3 / 4;
}

.desktop.noSFButton .endDate {
  grid-row-start: 1;
  grid-column: 4 / 5;
}

.desktop.noSFButton .passengers {
  grid-row-start: 1;
  grid-column: 5 / 6;
}

.desktop.oneway.noSFButton .passengers {
  grid-row-start: 1;
  grid-column: 4 / 5;
}

.back {
  border: none;
  background: none;
  padding: 0;
  text-align: left;
}

@media only screen and (max-width: 1439px) and (min-width: 991px) {
  .desktop.roundtrip {
    grid-template-columns:
      repeat(2, 19%)
      repeat(2, 15%)
      19.43% 0.5rem 1fr;
  }
  .desktop.roundtrip.noSFButton {
    transition: all 1s ease;
    transition-delay: 0.25s;
    grid-template-columns:
      repeat(2, 21.65%)
      repeat(2, 17.1%)
      22.5% 0rem 0fr;
  }

  .desktop.oneway {
    grid-template-columns:
      repeat(2, 21.85%)
      21.38%
      22.34% 0.5rem 0rem auto;
  }
  .desktop.oneway.noSFButton {
    transition: all 1s ease;
    transition-delay: 0.25s;
    grid-template-columns:
      repeat(2, 25%)
      21.38%
      28.62% 0rem 0rem 0fr;
  }
}
