:root {
  /* Colors */
  --UI-background: #f4f7f9;
  --primary-1: #1665e3;
  --primary-2: #001b38;
  --primary-3: #04b1a7;
  --primary-link-color: #166bc8;
  --active-link-color: var(--primary-1);
  --passive-link-color: var(--primary-2);
  --active-link-line: none;
  --active-link-line-offset: 0px;

  --primary-1-50: rgba(22, 101, 227, 0.5);
  --primary-2-50: rgba(0, 27, 56, 0.5);
  --primary-3-50: rgba(4, 177, 167, 0.5);
  --primary-link-color-50: rgba(22, 107, 200, 0.5);

  --loader-gradient: linear-gradient(var(--primary-1) 0 0),
    linear-gradient(var(--primary-1) 0 0), none;

  --search-button-color: #ff9900;

  --primary-btn-hover-color: hsl(217, 82%, 45%);
  --secondary-btn-hover-color: hsl(207, 24%, 89%);
  --search-button-hover-color: hsl(36, 100%, 46%);

  --ui-1: #ffffff;
  --ui-2: #f0f3f5;
  --ui-3: #e8edf1;
  --ui-4: #bac7d5;
  --ui-5: #4f5e71;
  --ui-6: #252a31;
  --text-2: #494f59;
  --text-3: #929598;
  --text-4: #9ba8b0;

  --success: #2eff8e;
  --error: #d21c1c;
  --warning: #ffd600;

  /* Toasts */
  --toastify-color-light: #ffffff;
  --toastify-color-dark: #001b38 !important;
  --toastify-color-info: var(--primary-1) !important;
  --toastify-color-success: var(--success) !important;
  --toastify-color-warning: var(--warning) !important;
  --toastify-color-error: #d21c1c !important;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-font-family: "Proxima Nova-Regular" !important;

  /* Fonts */
  --font-regular: sans-serif;
  --font-medium: sans-serif;
  --font-semibold: sans-serif;
  --font-bold: sans-serif;

  --regular-w: 400;
  --medium-w: 500;
  --semibold-w: 600;
  --bold-w: 700;

  --fmob-size-40px: 2.5rem;
  --fmob-size-32px: 2rem;
  --fmob-size-28px: 1.75rem;
  --fmob-size-26px: 1.625rem;
  --fmob-size-24px: 1.5rem;
  --fmob-size-20px: 1.25rem;
  --fmob-size-18px: 1.125rem;
  --fmob-size-16px: 1rem;
  --fmob-size-14px: 0.875rem;
  --fmob-size-12px: 0.75rem;
  --fmob-size-10px: 0.625rem;

  --f-size-40px: 2.5rem;
  --f-size-32px: 2rem;
  --f-size-28px: 1.75rem;
  --f-size-24px: 1.5rem;
  --f-size-26px: 1.625rem;
  --f-size-20px: 1.25rem;
  --f-size-18px: 1.125rem;
  --f-size-16px: 1rem;
  --f-size-14px: 0.875rem;
  --f-size-12px: 0.75rem;
  --f-size-10px: 0.625rem;

  --font-style: normal;
  --line-height: normal;

  --modal-anim-time: 400ms;
  --backdrop-anim-time: 200ms;

  --main-page-side-pad-relative: 3%;
  --main-page-content-max-width: 1440px;
  --main-page-inner-max-width: 1236px;
  --main-page-side-pad: 6.37rem;

  --zindex-low: 300;
  --zindex-medium-low: 600;
  --zindex-medium: 700;
  --zindex-high: 850;
  --zindex-highest: 1000;
  /* --scroll-bar-color: #e8edf179; */

  --rt-transition-show-delay: 0.3s !important;
  --rt-transition-closing-delay: 0.3s !important;

  --corner-sm: 3px;
  --corner-md: 5px;
  --corner-lg: 7px;
  --corner-xl: 10px;

  --checkbox-corner: 3px;
  --checkbox-accent-color: var(--primary-1);
  --checkbox-mark-thickness: 2;
  --radio-border: 1px var(--ui-4) solid;
  --radio-accent-bg: var(--primary-1);
  --radio-accent-border: 1px #fff solid;
  --radio-accent-inner: var(--primary-1);

  --sort-tag-best: #367cff;
  --sort-tag-cheap: #00a991;
  --nav-bar-color: #ffffff;
  --nav-bar-shadow: 0rem 0.25rem 0.25rem -0.125rem rgba(37, 42, 49, 0.12);
}
