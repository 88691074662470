.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container.menu {
  flex-direction: column;
  width: 225px;
}

.menu li:not(.divider) > a,
.menu .signout {
  color: var(--primary-2) !important;
  opacity: 1 !important;
  font-family: var(--font-semibold);
  font-size: var(--f-size-16px);
  font-weight: var(--semibold-w);
  line-height: var(--line-height);
  font-style: var(--font-style);
}

.menu li:not(.divider) > a:hover,
.menu .signout:hover {
  color: var(--primary-link-color) !important;
}

.menu li:not(.divider):not(:last-child) {
  margin-bottom: 15px;
}

.divider {
  margin-top: 5px;
  margin-bottom: 20px;
  border-style: solid;
  border-color: #d6e3f0;
  border-width: 1px 0 0 0;
}

.container.user {
  justify-content: flex-start;
  align-items: center;
  column-gap: 5px;
}

.miniIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  user-select: none;
  background-color: var(--ui-4);
}

.name {
  overflow-x: hidden;
  text-overflow: ellipsis;
  color: var(--primary-2) !important;
  font-family: var(--font-medium);
  font-size: var(--f-size-14px);
  font-weight: var(--medium-w);
  line-height: var(--line-height);
  font-style: var(--font-style);
}
.signout {
  border: none;
  background: none;
  padding: 0;
}
